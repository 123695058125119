import { DELIVERY_ORDER_INVOICING_STATUS } from '~/Modules/Payment/constants';

/* eslint-disable max-len */
export default {
  e_materai_fee_info: 'The {{fee}} fee is automatically deducted from the total invoice amount after tax when the invoice is approved.',
  e_materai_recommended: 'e-Stamp Duty (recommended)',
  e_materai_waiting_approval: 'e-Stamp Duty awaiting approval',
  max_selected: 'You can select a maximum of {{max}} items',
  refresh_pdf: 'Reload PDF',
  signature_missing_info: 'Invoices without e-Stamp Duty and digital signatures must be printed for wet signatures and then re-uploaded after review.',
  signature_only_missing_info: 'Invoices without digital signatures must be printed for wet signatures and then re-uploaded after review.',
  signature_only_uploaded_info: 'Invoices with digital signatures will be reviewed directly without needing to be printed and re-uploaded.',
  signature_recommended: 'Digital Signature (recommended)',
  signature_uploaded_info: 'Invoices with e-Stamp Duty and digital signatures will be reviewed directly without needing to be printed and re-uploaded.',
  delivery_order_invoicing_status: {
    [DELIVERY_ORDER_INVOICING_STATUS.NOT_INVOICED]: 'Can be Invoiced',
    [DELIVERY_ORDER_INVOICING_STATUS.PARTIALLY_INVOICED]: 'Partially Invoiced',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_CLAIM_APPROVAL]: 'Claim Processing',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_POD]: 'POD Not Received',
    [DELIVERY_ORDER_INVOICING_STATUS.ONGOING_SHIPMENT]: 'Shipment In Progress',
    [DELIVERY_ORDER_INVOICING_STATUS.SHIPPER_INVOICE_IS_BEING_PROCESSED]: 'Shipper Invoice in Progress',
    [DELIVERY_ORDER_INVOICING_STATUS.WAITING_FOR_INVOICE_PAID]: 'Waiting for Shipper Payment',
    [DELIVERY_ORDER_INVOICING_STATUS.EXPIRED_STNK]: 'STNK Expired',
  },
};
