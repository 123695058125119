import { get } from 'lodash-es';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const getUserProfile = data => {
  const employeeProfile = get(data, 'myEmployeeProfile');
  const companyDetails = get(data, 'myEmployeeProfile.company', {});
  const companyDocuments = get(data, 'companySettings.companyDocuments');
  const companyBankAccounts = get(data, 'companyBankAccounts');
  return {
    ksuid: get(employeeProfile, 'ksuid') || '',
    name: get(employeeProfile, 'name') || '',
    email: get(employeeProfile, 'user.email') || '',
    phoneNumber: get(employeeProfile, 'user.phoneNumber') || '',
    defaultLocale: get(employeeProfile, 'user.defaultLocale'),
    passwordExpiredAt: get(employeeProfile, 'user.passwordExpiredAt') || '',
    company: {
      ...companyDetails,
      accessType: get(employeeProfile, 'accessType', ''),
      accessTypes: get(employeeProfile, 'accessTypes', []),
      signatureName: get(companyDetails, 'signatureName', ''),
      signatureTitle: get(companyDetails, 'signatureTitle', ''),
      address: get(companyDetails, 'address') || '',
      email: get(companyDetails, 'email') || '',
      handle: get(companyDetails, 'handle'),
      hasConsentedCustomer: Boolean(get(companyDetails, 'hasConsentedCustomer')),
      isPartneredWithEnterpriseShipper: get(companyDetails, 'isPartneredWithEnterpriseShipper'),
      isDofEligible: Boolean(get(companyDetails, 'isDofEligible')),
      isPodfEligible: Boolean(get(companyDetails, 'isPodfEligible')),
      ksuid: get(companyDetails, 'ksuid'),
      logo: {
        url: get(companyDetails, 'logo.url.full'),
        fileName: get(companyDetails, 'logo.fileName') || '',
      },
      metadata: get(companyDetails, 'metadata') || '',
      name: get(companyDetails, 'name') || '',
      driverPbiRegistrationSummary: get(companyDetails, 'driverPbiRegistrationSummary'),
      pbiTransporterVerification: get(companyDetails, 'pbiTransporterVerification'),
      phoneNumber: get(companyDetails, 'phoneNumber') || '',
      status: get(companyDetails, 'status'),
      verifications: get(companyDetails, 'verifications'),
      type: get(companyDetails, 'type'),
      companyDocuments: get(companyDetails, 'documents'),
      documents: {
        ktp: {
          url: get(companyDocuments, 'ktpFile.url.full'),
          fileName: get(companyDocuments, 'ktpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'ktpFile.uploadedAt.datetime'),
        },
        npwp: {
          url: get(companyDocuments, 'npwpFile.url.full'),
          fileName: get(companyDocuments, 'npwpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'npwpFile.uploadedAt.datetime'),
        },
        sppkp: {
          url: get(companyDocuments, 'sppkpFile.url.full'),
          fileName: get(companyDocuments, 'sppkpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'sppkpFile.uploadedAt.datetime'),
        },
      },
      vehicleUtilizationTarget: get(companyDetails, 'vehicleUtilizationTarget'),
      countryCode: get(companyDetails, 'countryCode') || 'ID',
    },
    bankAccounts: Array.isArray(companyBankAccounts)
      ? companyBankAccounts.map(account => ({
        ksuid: get(account, 'ksuid'),
        accountHolder: get(account, 'accountHolder'),
        accountNumber: get(account, 'accountNumber'),
        isDefault: get(account, 'isDefault'),
        bank: {
          ksuid: get(account, 'bank.ksuid'),
          name: get(account, 'bank.name'),
          bankCode: get(account, 'bank.bankCode'),
          swiftCode: get(account, 'bank.swiftCode'),
        },
      }))
      : [],
  };
};

export default getUserProfile;
