import moment from 'moment';

/* eslint-disable max-len */
export const AR_AGING_LABELS = {
  AGING_DUE: 'AGING_DUE',
  AGING_DUE_TODAY: 'AGING_DUE_TODAY',
  AGING_OVERDUE: 'AGING_OVERDUE',
};

export const AR_AGING_STATUSES = {
  DISBURSED: 'DISBURSED',
  ON_HOLD: 'ON_HOLD',
  REQUESTED: 'REQUESTED',
  SECOND_DISBURSEMENT_APPROVED: 'SECOND_DISBURSEMENT_APPROVED',
  SECOND_DISBURSEMENT_IN_PROCESS: 'SECOND_DISBURSEMENT_IN_PROCESS',
  SECOND_DISBURSEMENT_REQUESTED: 'SECOND_DISBURSEMENT_REQUESTED',
  SECOND_DISBURSEMENT_DISBURSED: 'SECOND_DISBURSEMENT_DISBURSED',
  SECOND_DISBURSEMENT_ON_HOLD: 'SECOND_DISBURSEMENT_ON_HOLD',
  SECOND_DISBURSEMENT_REJECTED: 'SECOND_DISBURSEMENT_REJECTED',
  THIRD_DISBURSEMENT_DISBURSED: 'THIRD_DISBURSEMENT_DISBURSED',
  THIRD_DISBURSEMENT_IN_PROCESS: 'THIRD_DISBURSEMENT_IN_PROCESS',
};

export const AR_AGING_STATUS_COLORS = {
  DISBURSED: 'success',
  SECOND_DISBURSEMENT_APPROVED: 'primary',
  SECOND_DISBURSEMENT_IN_PROCESS: 'warning',
  SECOND_DISBURSEMENT_REQUESTED: 'warning',
  SECOND_DISBURSEMENT_DISBURSED: 'success',
  SECOND_DISBURSEMENT_ON_HOLD: 'warning',
  SECOND_DISBURSEMENT_REJECTED: 'warning',
  THIRD_DISBURSEMENT_IN_PROCESS: 'warning',
  THIRD_DISBURSEMENT_DISBURSED: 'success',
};

export const COMPANY_ADDRESS_TYPES = {
  BILLING: 'BILLING',
  COMPANY: 'COMPANY',
};

export const CUSTOMER_CONSENT_TYPES = {
  COMPANY: 'COMPANY',
  PARTNER: 'PARTNER',
};

export const COMPANY_TYPES = {
  CV: 'CV',
  FA: 'FA',
  PT: 'PT',
  UD: 'UD',
};

export const DO_CANCELLATION_REASONS = {
  ALLOCATION_REVISION: 'ALLOCATION_REVISION',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  COMPLIANCE_ISSUE: 'COMPLIANCE_ISSUE',
  DRIVER_ABSENCE: 'DRIVER_ABSENCE',
  DRIVER_ILL: 'DRIVER_ILL',
  DRIVER_NOT_VACCINATED: 'DRIVER_NOT_VACCINATED',
  EXPIRED_KIR: 'EXPIRED_KIR',
  EXPIRED_SIM: 'EXPIRED_SIM',
  EXPIRED_STNK: 'EXPIRED_STNK',
  FORCE_MAJEURE: 'FORCE_MAJEURE',
  FULL_STOCK: 'FULL_STOCK',
  LOADING_DELAY: 'LOADING_DELAY',
  NO_EMPTY_JUGS: 'NO_EMPTY_JUGS',
  NO_PALLET_JUGRACK: 'NO_PALLET_JUGRACK',
  NO_STOCK: 'NO_STOCK',
  OTHER_REASON: 'OTHER_REASON',
  TKBM_ISSUE: 'TKBM_ISSUE',
  TRUCK_NO_SHOW: 'TRUCK_NO_SHOW',
  VEHICLE_ISSUE: 'VEHICLE_ISSUE',
  VEHICLE_TAX_NOT_PAID: 'VEHICLE_TAX_NOT_PAID',
  WRONG_BID_INPUT: 'WRONG_BID_INPUT',
  CUSTOMER_ORDER_ABORTED: 'CUSTOMER_ORDER_ABORTED',
  MOVED_TO_ANOTHER_PLATFORM: 'MOVED_TO_ANOTHER_PLATFORM',
};

export const DO_REJECTION_REASONS = {
  VEHICLE_NOT_READY: 'VEHICLE_NOT_READY',
  VEHICLE_MAINTENANCE_NEEDED: 'VEHICLE_MAINTENANCE_NEEDED',
  DRIVER_NOT_READY: 'DRIVER_NOT_READY',
  OTHER_REASON: 'OTHER_REASON',
};

export const DOCUMENT_FORM_SEGMENT_INDEX = {
  CUSTOMER_DATA: 0,
  MAIN_DOCUMENTS: 1,
  FINANCIAL_LEGAL_DOCUMENTS: 2,
  CUSTOMER_DOCUMENTS: 3,
  REVIEW_DOCUMENTS: 4,
};

export const SHIPPER_COMPANY_DOCUMENT_TYPES = {
  TDP: 'TDP',
  KTP: 'KTP',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  NPWP: 'NPWP',
  PKP: 'PKP',
};

export const COMPANY_PARTNER_TYPE = {
  PARTNER: 'PARTNER',
  COMPANY: 'COMPANY',
};

export const SHOW_CLAIMS_IN = {
  POD: 'POD',
  BERITA_ACARA: 'BERITA_ACARA',
  LOAD_ITEMS: 'LOAD_ITEMS',
};

export const IS_RETURN_GOODS = {
  NO_RETURN: 'NO_RETURN',
  RETURN_GOODS: 'RETURN_GOODS',
};

export const GOODS_REJECTION_CATEGORY = {
  CLAIM: 'CLAIM',
  NO_CLAIM: 'NO_CLAIM',
  REPACKING: 'REPACKING',
};

export const IS_RETURN_FEE_COVERED = {
  NEGOTIABLE: 'NEGOTIABLE',
  NOT_COVERED: 'NOT_COVERED',
};

export const OTP_OPTIONS = {
  REGISTRATION: 'REGISTRATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FIRST_TIME_LOGIN: 'FIRST_TIME_LOGIN',
};

export const PLATE_COLORS = {
  BLACK_PLATE: 'BLACK_PLATE',
  YELLOW_PLATE: 'YELLOW_PLATE',
};

export const LOCATION_FEE_TYPE = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  OTHER: 'OTHER',
};

export const LOCATION_TYPE = {
  NORMAL: 'NORMAL',
  HOLDING: 'HOLDING',
  PORT: 'PORT',
  RETURN_DEPO: 'RETURN_DEPO',
  STORE: 'STORE',
};

export const ROUTE_LOCATION_GROUP = {
  PORT_STORE_RETURN_DEPO: [
    LOCATION_TYPE.PORT, LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO,
  ],
  PORT_HOLDING: [
    LOCATION_TYPE.PORT,
    LOCATION_TYPE.HOLDING,
  ],
  HOLDING_STORE_RETURN_DEPO: [
    LOCATION_TYPE.HOLDING,
    LOCATION_TYPE.STORE,
    LOCATION_TYPE.RETURN_DEPO,
  ],
};

export const PAYMENT_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  PPN: 'PPN',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  TICKET_FEE: 'TICKET_FEE',
  OTHER_FEE: '',
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
  TOTAL_FEE: 'TOTAL_FEE',
};

export const INVOICE_CREATION_FEE_TYPE_FILTERS = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
};

export const INVOICE_CREATION_FEE_TYPE_FILTER_VALUES = {
  [INVOICE_CREATION_FEE_TYPE_FILTERS.SHIPMENT_FEE]: 'SHIPMENT_FEE_CATEGORY',
  [INVOICE_CREATION_FEE_TYPE_FILTERS.ADDITIONAL_FEE]: 'ADDITIONAL_FEE_CATEGORY',
};

export const INVOICABLE_SHIPMENT_FEE_VALUES = {
  [INVOICE_CREATION_FEE_TYPE_FILTERS.SHIPMENT_FEE]: 'shipment',
  [INVOICE_CREATION_FEE_TYPE_FILTERS.ADDITIONAL_FEE]: 'additional',
};

export const PPN_PERCENTAGES = {
  0: '0%',
  1: '1%',
  1.1: '1.1%',
  10: '10%',
  11: '11%',
};

export const OVERNIGHT_TYPES = {
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
};

export const VALID_DOC_TYPES = {
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  CLAIM_AND_RETURN: 'CLAIM_AND_RETURN',
};

export const UPLOADED_FROM = {
  DA: 'DA',
  TMS: 'TMS',
};

export const ADDITIONAL_PAYMENT_TYPES = [
  PAYMENT_TYPES.LOADING_FEE,
  PAYMENT_TYPES.UNLOADING_FEE,
  PAYMENT_TYPES.OVERNIGHT_FEE,
  PAYMENT_TYPES.TICKET_FEE,
  PAYMENT_TYPES.OTHER_FEE,
];

export const SERVICES_URL = {
  PANTHERA: window.PANTHERA_SERVICE_URL,
  LOAD: window.LOAD_SERVICE_URL,
  EVENT: window.EVENT_SERVICE_URL,
  PROFILE: window.PROFILE_SERVICE_URL,
  MASTER_SERVICE: window.MASTER_SERVICE_URL,
  AUTH: window.AUTH_SERVICE_URL,
  PROFILE_PUBLIC: window.PROFILE_SERVICE_URL_PUBLIC,
  PAYMENT: window.PAYMENT_SERVICE_URL,
  SSE: window.ATLAS_SSE_SERVICE_URL,
  NEW_APOLLO_BACKEND: window.APOLLO_BACKEND_API_URL,
  TRANSPORTER_PROFILE: window.TRANSPORTER_PROFILE_URL,
};

export const EVENT_SERVICE_DEVICE_TYPE = 'transporter_central';

export const VEHICLE_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const VEHICLE_STATUS_COLORS = {
  [VEHICLE_STATUSES.DRAFT]: 'darkGrey',
  [VEHICLE_STATUSES.PENDING]: 'warning',
  [VEHICLE_STATUSES.ACTIVE]: 'success',
  [VEHICLE_STATUSES.DEACTIVATED]: 'danger',
  [VEHICLE_STATUSES.REGISTERED]: 'success',
  [VEHICLE_STATUSES.EXPIRED]: 'danger',
  [VEHICLE_STATUSES.REJECTED]: 'danger',
};

export const DRIVER_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CREATED: 'CREATED',
  DOCUMENT_EXPIRED: 'DOCUMENT_EXPIRED',
};

export const DRIVER_STATUS_COLORS = {
  [DRIVER_STATUSES.SUSPENDED]: 'danger',
  [DRIVER_STATUSES.DEACTIVATED]: 'danger',
  [DRIVER_STATUSES.ACTIVE]: 'success',
  [DRIVER_STATUSES.REGISTERED]: 'success',
  [DRIVER_STATUSES.INACTIVE]: 'danger',
  [DRIVER_STATUSES.CREATED]: 'darkGrey',
  [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'danger',
};

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  INACTIVE: 'INACTIVE',
};

export const USER_STATUS_COLORS = {
  [USER_STATUSES.ACTIVE]: 'success',
  [USER_STATUSES.REGISTERED]: 'warning',
  [USER_STATUSES.INACTIVE]: 'danger',
};

export const NON_EDITABLE_USER_STATUSES = [USER_STATUSES.BLOCKED];

export const INVOICE_TEMPLATE_TYPES = {
  DEFAULT_TEMPLATE: 'DEFAULT_TEMPLATE',
};

export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER',
  OTHER_DOCUMENT: 'OTHER_DOCUMENT',
};

export const SHIPMENT_DOCUMENT_TYPES = {
  INTERNAL_POD: 'internal-pod',
  INTERNAL_POL: 'internal-pol',
  CLAIMS_AND_RETURNS: 'claims-returns',
  OTHER_DOCUMENT: 'other-document',
};

export const DOCUMENT_VERIFICATION_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  NEGLECTED: 'NEGLECTED',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  REJECTED: 'REJECTED',
};

export const LANE_FILTER_TYPE = {
  CITY_TO_CITY: 'CITY_TO_CITY',
};

export const LOCALE_TYPE = {
  id: 'id',
  en: 'en',
};

export const CUSTOMER_DIVISION = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSE: 'WAREHOUSE',
  SALES: 'SALES',
  FINANCE: 'FINANCE',
  // Commented out for possible future use. Currently only valid for B-TMS - See common translations
  // PROCUREMENT: 'PROCUREMENT',
  // LOGISTICS: 'LOGISTICS',
  // TREASURY: 'TREASURY',
  // CLAIMS: 'CLAIMS',
};

export const CSV_SEPERATORS = {
  COMMA: 'COMMA',
  COLON: 'COLON',
  PIPE: 'PIPE',
  SEMICOLON: 'SEMICOLON',
  TAB: 'TAB',
};

export const PANTHERA_PIC_DIVISION = {
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
  CANVASSER: 'CANVASSER',
};

// Unsorted on purpose (Due to in-component mapping)
export const LOCAL_SHIPMENT_STATUSES = {
  ALL: 'all',
  UPCOMING: 'upcoming',
  PLANNED: 'planned',
  AT_WAREHOUSE: 'at-warehouse',
  IN_TRANSIT: 'in-transit',
  AT_LOCATION: 'at-location',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
};

export const WEIGHT_UNITS = {
  TON: 'TON',
  CBM: 'CBM',
};

export const CAPACITY_UNIT = {
  KG: 'KG',
  CBM: 'CBM',
  UNIT: 'UNIT',
  TON: 'TON',
};

export const INDONESIA_TIMEZONE = {
  7: 'WIB',
  8: 'WITA',
  9: 'WIT',
};

export const MALAYSIA_TIMEZONE = {
  8: 'GMT+8',
};

export const PPH23_DEDUCTOR = {
  PARTNER: 'PARTNER',
  SELF: 'SELF',
};

export const LAST_DISBURSEMENT_PPH23_DEDUCTOR = {
  SHIPPER: 'SHIPPER',
  TRANSPORTER: 'TRANSPORTER',
};

export const SHIPMENT_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ALLOCATE: 'ALLOCATE',
  REALLOCATE: 'REALLOCATE',
  UPDATE_DO_NUMBER: 'UPDATE_DO_NUMBER',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  ASSIGN_SHIPMENT: 'ASSIGN_SHIPMENT',
  CREATE_REMINDER: 'CREATE_REMINDER',
  REOPEN_JOB: 'REOPEN_JOB',
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  UNFULFILLMENT_SHIPMENT: 'UNFULFILLMENT_SHIPMENT',
  CANCEL_MARKETPLACE_JOB: 'CANCEL_MARKETPLACE_JOB',
  APPROVE_REASSIGNMENT: 'APPROVE_REASSIGNMENT',
  SET_BID_BASE_PRICE: 'SET_BID_BASE_PRICE',
  SET_BID_WINNER: 'SET_BID_WINNER',
};

export const SHIPMENT_TEMPLATE_FORM_TYPES = {
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  USE_TEMPLATE: 'USE_TEMPLATE',
  ALLOCATE: 'ALLOCATE',
};

export const SHIPMENT_CONFIRMATION_FORM_TYPES = {
  ACCEPT_NOW: 'ACCEPT_NOW',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  SHOW_ALREADY_ASSIGN_WARNING: 'SHOW_ALREADY_ASSIGN_WARNING',
};

export const SHIPPER_FORM_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const SHIPMENT_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  ALLOCATION_FORM: 'ALLOCATION_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const SHIPMENT_STATUSES = {
  ACTIVATE_VEHICLE_ALERT: 'ACTIVATE_VEHICLE_ALERT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  ASSIGNED: 'ASSIGNED',
  BUMP_DOWN_ACTIVE_SHIPMENT: 'BUMP_DOWN_ACTIVE_SHIPMENT',
  BUMP_UP_QUEUED_SHIPMENT: 'BUMP_UP_QUEUED_SHIPMENT',
  CANCELLED: 'CANCELLED',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  DEACTIVATE_VEHICLE_ALERT: 'DEACTIVATE_VEHICLE_ALERT',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  FINISH_LOADING: 'FINISH_LOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  // JOB_TRANSPORTER_CONFIRMED to not be used for status filters
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED', // used by BTMS tpt confirmed DO
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ON_SHIPMENT: 'ON_SHIPMENT',
  PLANNED: 'PLANNED',
  COMPLETED: 'COMPLETED',
  ONGOING: 'ONGOING',
  LOADING: 'LOADING',
  SCHEDULED: 'SCHEDULED',
  UNLOADING: 'UNLOADING',
  SHIPMENT: 'SHIPMENT',
  // used for upcoming shipments - shipment/upcoming (after btms integration)
  NEED_CONFIRMATION: 'NEED_CONFIRMATION',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REASSIGNED_BY_CUSTOMER: 'REASSIGNED_BY_CUSTOMER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  UNFULFILLED_BY_TRANSPORTER: 'UNFULFILLED_BY_TRANSPORTER',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  START_LOADING: 'START_LOADING',
  START_UNLOADING: 'START_UNLOADING',
  UNFULFILLED: 'UNFULFILLED',
  UPCOMING: 'UPCOMING',
  // Two of these are used specifically for shipment > planned tab (vehicle assigned/not assigned)
  VEHICLE_ASSIGNED: 'VEHICLE_ASSIGNED',
  VEHICLE_NOT_ASSIGNED: 'VEHICLE_NOT_ASSIGNED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_TRUCK_DATA: 'WAITING_FOR_TRUCK_DATA', // doesn't have translation map
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  'CANCELLED_BY_INTERNAL,CANCELLED_BY_SHIPPER': ['CANCELLED_BY_INTERNAL', 'CANCELLED_BY_SHIPPER'],
};

export const BTMS_DRIVER_REASSIGN_STATUS = {
  REASSIGNMENT_APPROVED: 'REASSIGNMENT_APPROVED',
  REASSIGNMENT_APPROVED_BY_BROKERAGE: 'REASSIGNMENT_APPROVED_BY_BROKERAGE',
  REASSIGNMENT_APPROVED_BY_SHIPPER: 'REASSIGNMENT_APPROVED_BY_SHIPPER',
  REASSIGNMENT_CANCELLED: 'REASSIGNMENT_CANCELLED',
  REASSIGNMENT_PENDING: 'REASSIGNMENT_PENDING',
  REASSIGNMENT_REJECTED: 'REASSIGNMENT_REJECTED',
};

export const JOB_STATUSES = {
  OPEN: 'OPEN',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
};

export const SHIPMENT_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const SHIPPER_STATUSES = {
  CHURN: 'CHURN',
  PENDING_ONBOARDING: 'PENDING_ONBOARDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
};

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'ALCOHOLIC_BEVARAGES',
  DRINKING_WATER: 'DRINKING_WATER',
  COFFEE: 'COFFEE',
  FRUIT_FLAVORED_DRINKS: 'FRUIT_FLAVORED_DRINKS',
  JUICE: 'JUICE',
  MILK: 'MILK',
  NON_DAIRY: 'NON_DAIRY',
  POWDERED_BEVERAGES_MIXES: 'POWDERED_BEVERAGES_MIXES',
  SODA: 'SODA',
  SPORTS_ENERGY_DRINKS: 'SPORTS_ENERGY_DRINKS',
  TEA_INFUSIONS: 'TEA_INFUSIONS',
  WATER: 'WATER',
  BAKERY: 'BAKERY',
  SNAKES: 'SNAKES',
  CONDIMENTS_SOUCES: 'CONDIMENTS_SOUCES',
  COOKING_BAKING_INGREDIENTS: 'COOKING_BAKING_INGREDIENTS',
  DAIRY: 'DAIRY',
  DIPS: 'DIPS',
  FOOD: 'FOOD',
  FROZEN: 'FROZEN',
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  MEAT: 'MEAT',
  NUTS: 'NUTS',
  PASTA: 'PASTA',
  PREPARED: 'PREPARED',
  SPICES: 'SPICES',
  SNACKS: 'SNACKS',
  SOUPS: 'SOUPS',
  TOUFU: 'TOUFU',
  FITNESS_AND_NUTRITION: 'FITNESS_AND_NUTRITION',
  MEDICINE_AND_DRUGS: 'MEDICINE_AND_DRUGS',
  FMCG: 'FMCG',
  PLASTIC: 'PLASTIC',
  PACKAGING: 'PACKAGING',
  // NOTE: From BTMS Products
  AUTOMOTIVE: 'Automotive',
  MOVING_GOODS: 'Moving Goods',
  CONSTRUCTION_MATERIAL: 'Construction Material',
  HEAVY_EQUIPMENT: 'Heavy Equipment',
  FROZEN_GOODS: 'Frozen Goods',
  AGRICULTURAL_PRODUCT: 'Agricultural Product',
  GENERAL_CARGO: 'General Cargo',
  GLASSWARE: 'Glassware',
  PRODUCT_PACKAGING: 'Product Packaging',
};

export const SHIPPER_TYPES = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
};

export const INDUSTRY = {
  FMCG: 'FMCG',
  RAW_MATERIAL_OR_ORM: 'RAW_MATERIAL_OR_ORM',
  LOGISTIC_COMPANY: 'LOGISTIC_COMPANY',
  BROKER_TRANSPORTER: 'BROKER_TRANSPORTER',
  E_COMMERCE: 'E_COMMERCE',
  COMMODITY_AND_TRADING: 'COMMODITY_AND_TRADING',
};

export const REIMBURSEMENT_REQUIREMENT_TYPE = {
  REIMBURSABLE_WITH_RECEIPT_AND_STAMP: 'REIMBURSABLE_WITH_RECEIPT_AND_STAMP',
  REIMBURSABLE_WITH_RECEIPT: 'REIMBURSABLE_WITH_RECEIPT',
  REIMBURSABLE: 'REIMBURSABLE',
  NOT_REIMBURSABLE: 'NOT_REIMBURSABLE',
  OTHER: 'OTHER',
};

export const REIMBURSEMENT_TYPE = {
  ROUTE: 'ROUTE',
  UNIT: 'UNIT',
  KG: 'KG',
  OTHER: 'OTHER',
};

export const SHIPPER_STATUS_COLORS = {
  [SHIPPER_STATUSES.CHURN]: 'danger',
  [SHIPPER_STATUSES.INACTIVE]: 'warning',
  [SHIPPER_STATUSES.ACTIVE]: 'success',
  [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'grey',
  [SHIPPER_STATUSES.SUSPENDED]: 'danger',
  [SHIPPER_STATUSES.REJECTED]: 'danger',
};

export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.NEED_CONFIRMATION]: 'warning',
  [SHIPMENT_STATUSES.UPCOMING]: 'danger',
  [SHIPMENT_STATUSES.PLANNED]: 'danger',
  [SHIPMENT_STATUSES.ASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.NOT_ASSIGNED]: 'danger',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.LOADING]: 'warning',
  [SHIPMENT_STATUSES.UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.ONGOING]: 'warning',
  [SHIPMENT_STATUSES.SCHEDULED]: 'default',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'success',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'primary',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'success',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_CUSTOMER]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_CUSTOMER]: 'danger',
  [SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED]: 'danger',
  [SHIPMENT_STATUSES.VEHICLE_ASSIGNED]: 'darkGrey',
};

export const MORE_FILTER_KEYS = {
  ORIGIN_LOCATION_FIELD: 'originWarehouseKsuid',
  DESTINATION_LOCATION_FIELD: 'destinationWarehouseKsuid',
  LOADING_DATE_FIELD: 'loadingDate',
  UNLOADING_DATE_FIELD: 'unloadingDate',
  ARRIVE_AT_ORIGIN_DATE_FIELD: 'arriveAtOriginDate',
  ARRIVE_AT_DESTINATION_DATE_FIELD: 'arriveAtDestinationDate',
  TAGS_FIELD: 'tags',
  SUPPORT_FIELD: 'createdByKsuid',
  EXTERNAL_ID: 'externalId',
  GROUP_LOAD_NUMBER: 'groupLoadNumber',
  TRANSPORTER_COMPANY: 'transporterCompanyKsuids',
  DRIVER: 'driverKsuids',
};

export const SHIPMENT_STATUS_LOCAL_GROUPS = {
  // TODO: check if can be removed as DERIVED_UPCOMING_SHIPMENT_STATUSES will be substitute for this
  [LOCAL_SHIPMENT_STATUSES.UPCOMING]: [SHIPMENT_STATUSES.UPCOMING],
  // TODO: check if can be removed as it is changed to DERIVED_PLANNED_TAB_STATUSES
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.HISTORY]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
    SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL,
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [SHIPMENT_STATUSES.ONGOING_TO_PICKUP, SHIPMENT_STATUSES.ON_SHIPMENT],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER,
    SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER,
    SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
    SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
  ],

  [LOCAL_SHIPMENT_STATUSES.ALL]: [
    SHIPMENT_STATUSES.UPCOMING,
    SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
    SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
    SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES['CANCELLED_BY_INTERNAL,CANCELLED_BY_SHIPPER'],
    SHIPMENT_STATUSES.PLANNED,
    SHIPMENT_STATUSES.ASSIGNED,
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  ],
};

export const DERIVED_ALL_SHIPMENT_TAB_STATUSES = [
  // Substitute for previous upcoming shipment mapping
  SHIPMENT_STATUSES.NEED_CONFIRMATION,
  // Substitute for previous planned shipment mapping
  SHIPMENT_STATUSES.VEHICLE_ASSIGNED,
  SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
  SHIPMENT_STATUSES['CANCELLED_BY_INTERNAL,CANCELLED_BY_SHIPPER'],
  SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER,
  SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
  SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
  SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
];

export const DERIVED_PLANNED_TAB_STATUSES = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
    SHIPMENT_STATUSES.VEHICLE_ASSIGNED,
  ],
};

export const DERIVED_STATUSES_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
    SHIPMENT_STATUSES.VEHICLE_ASSIGNED,
  ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_CUSTOMER,
    SHIPMENT_STATUSES.REASSIGNED_BY_CUSTOMER,
    SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
    SHIPMENT_STATUSES.UNFULFILLED_BY_TRANSPORTER,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ON_SHIPMENT,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING,
    SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION,
  ],
};

/**
 * Change plannedCount and assignedCount to vehicleUnassignedCount and vehicleAssignedCount
 * when removing CONTRACTED_BTMS_DO
 */
export const SHIPMENT_COUNT_QUERY_NAME = {
  [SHIPMENT_STATUSES.NEED_CONFIRMATION]: 'needConfirmationCount',
  [SHIPMENT_STATUSES.UPCOMING]: 'upcomingCount',
  [SHIPMENT_STATUSES.PLANNED]: 'plannedCount',
  [SHIPMENT_STATUSES.ASSIGNED]: 'assignedCount',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'onShipmentCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
  [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDestinationCount',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'shipmentCompleteCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'cancelledByTransporterCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByShipperCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'cancelledByInternalCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByCustomerCount',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'unfulfilledByTransporterCount',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'confirmationExpiredCount',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'reassignedByCustomerCount',
};

export const SHIPMENT_STATUS_COUNTS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.ALL]: [
    SHIPMENT_STATUSES.NEED_CONFIRMATION,
    SHIPMENT_STATUSES.SCHEDULED,
    SHIPMENT_STATUSES.ONGOING,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [
    SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
    SHIPMENT_STATUSES.VEHICLE_ASSIGNED,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.START_LOADING,
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.SHIPMENT,
    SHIPMENT_STATUSES.LOADING,
    SHIPMENT_STATUSES.UNLOADING,
  ].filter(Boolean),
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.CANCELLED_BY_CUSTOMER,
    SHIPMENT_STATUSES.REASSIGNED_BY_CUSTOMER,
    SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
    SHIPMENT_STATUSES.UNFULFILLED_BY_TRANSPORTER,
  ].filter(Boolean),
};

export const SHIPMENT_STATUS_TOOLTIP = {
  [SHIPMENT_STATUSES.SHIPMENT]: {
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'ongoingToPickupCount',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'ongoingToDropoffCount',
  },
  [SHIPMENT_STATUSES.LOADING]: {
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
    [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'finishLoadingCount',
  },
  [SHIPMENT_STATUSES.UNLOADING]: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDropoffCount',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  },
  [SHIPMENT_STATUSES.SCHEDULED]: {
    [SHIPMENT_STATUSES.VEHICLE_ASSIGNED]: 'vehicleAssignedCount',
    [SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED]: 'vehicleNotAssignedCount',
  },
  [SHIPMENT_STATUSES.ONGOING]: {
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'ongoingToPickupCount',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
    [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'finishLoadingCount',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'ongoingToDropoffCount',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDropoffCount',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'waitingForVerificationCount',
  },
};

export const BTMS_DO_CANCELATION_PREREQUISITE = [
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
];

export const BTMS_DO_DRIVER_ASSIGNMENT_PREREQUISITE = [
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED,
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ONGOING_TO_DESTINATION,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
];

export const FLEET_MONITORING_STATUS = {
  AVAILABLE: 'AVAILABLE',
  ONGOING: 'ONGOING',
};
export const GPS_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  IDLE: 'IDLE',
};
export const GPS_STATUS_COLORS = {
  ONLINE: 'success',
  OFFLINE: 'gray',
  IDLE: 'orangeyYellow',
};
export const FLEET_MONITORING_STATUS_COLORS = {
  [FLEET_MONITORING_STATUS.AVAILABLE]: 'success',
  [FLEET_MONITORING_STATUS.ONGOING]: 'orangeyYellow',
};

export const ARMADA_SHIPMENT_STATUSES = [
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE,
];

export const GENERAL_ENTITY_STATUSES = {
  REGISTERED: 'REGISTERED',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REGISTERED: 'REGISTERED',
  CHURN: 'CHURN',
  DEACTIVATED: 'DEACTIVATED',
};

export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUSES.ACTIVE]: 'success',
  [COMPANY_STATUSES.INACTIVE]: 'danger',
  [COMPANY_STATUSES.REGISTERED]: 'orangeyYellow',
  [COMPANY_STATUSES.CHURN]: 'danger',
  [COMPANY_STATUSES.DEACTIVATED]: 'danger',
};

export const GENERAL_ENTITY_STATUS_COLORS = {
  REGISTERED: 'success',
  DEACTIVATED: 'danger',
};

// Unsorted on purpose for user access type mapped display
export const USER_ACCESS_TYPE = {
  ADMIN: 'ADMIN',
  AR: 'AR',
  FULL_ACCESS: 'FULL_ACCESS',
  FINANCE: 'FINANCE',
  PRICE_RESTRICTED_ACCESS: 'PRICE_RESTRICTED_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  FINANCE_ADMIN: 'FINANCE_ADMIN',
  TRANSPORT_PLANNER: 'TRANSPORT_PLANNER',
  ACCOUNT_PAYABLE: 'ACCOUNT_PAYABLE',
  TRAFFIC_CONTROLLER: 'TRAFFIC_CONTROLLER',
  SALES_SUPPORT: 'SALES_SUPPORT',
  DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
};

// This constant will prevent user to change their access type to any of the access type that is not in this list
export const ALLOWED_USER_ACCESS_TYPES = [
  USER_ACCESS_TYPE.FULL_ACCESS,
  USER_ACCESS_TYPE.RESTRICTED_ACCESS,
  USER_ACCESS_TYPE.FINANCE,
  USER_ACCESS_TYPE.PRICE_RESTRICTED_ACCESS,
];

export const FEE_BANNER_STATUSES = {
  CANCELLED: 'CANCELLED',
  DISBURSED: 'DISBURSED',
  REVIEW_IN_PROCESS: 'REVIEW_IN_PROCESS',
  REVIEW_SHIPMENT_FEE: 'REVIEW_SHIPMENT_FEE',
  ON_HOLD: 'ON_HOLD',
  PARTIALLY_DISBURSED: 'PARTIALLY_DISBURSED',
  REJECTED: 'REJECTED',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_INVOICE: 'WAITING_FOR_INVOICE',
  WAITING_FOR_INVOICE_REVISION: 'WAITING_FOR_INVOICE_REVISION',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  WAITING_FOR_PENALTY: 'WAITING_FOR_PENALTY',
  WAITING_FOR_TKBM: 'WAITING_FOR_TKBM',
};

export const FEE_TYPE_STATUSES = {
  CANCELLED: 'CANCELLED',
  DISBURSED: 'DISBURSED',
  FEE_REJECTED: 'FEE_REJECTED',
  REVIEW_IN_PROCESS: 'REVIEW_IN_PROCESS',
  REVIEW_SHIPMENT_FEE: 'REVIEW_SHIPMENT_FEE',
  ON_HOLD: 'ON_HOLD',
  PARTIALLY_DISBURSED: 'PARTIALLY_DISBURSED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  REQUEST_SENT: 'REQUEST_SENT',
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_INVOICE: 'WAITING_FOR_INVOICE',
  WAITING_FOR_INVOICE_REVISION: 'WAITING_FOR_INVOICE_REVISION',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  WAITING_FOR_PENALTY: 'WAITING_FOR_PENALTY',
  WAITING_FOR_TKBM: 'WAITING_FOR_TKBM',
};

export const FEE_TYPE_STATUS_COLORS = {
  APPROVED: 'primary',
  PENDING: 'primary',
  DISBURSEMENT_FAILED: 'danger',
  ON_HOLD: 'warning',
  PROCESSING: 'primary',
  REVIEWED: 'primary',
  FEE_REJECTED: 'danger',
  REQUEST_SENT: 'primary',
  CANCELLED: 'warning',
  REQUEST_APPROVED: 'primary',
  DISBURSED: 'success',
  DELETED: 'danger',
};

export const FEE_REQUESTS = {
  ADDITIONAL: 'ADDITIONAL',
  ADJUSTMENT: 'ADJUSTMENT',
  CLAIMS: 'CLAIMS',
  INSURANCE: 'INSURANCE',
  KARGO: 'KARGO',
  LATE: 'LATE',
  LOADING: 'LOADING',
  OTHER: 'OTHER',
  OTHER_ADJUSTMENT: 'OTHER_ADJUSTMENT',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING',
  PENALTY: 'PENALTY',
  RETURNS: 'RETURNS',
  SHIPMENT: 'SHIPMENT',
  TICKET: 'TICKET',
  UNLOADING: 'UNLOADING',
};

export const FEE_TYPES = {
  ADDITIONAL_FEE: 'ADDITIONAL_FEE',
  CANCELLATION_FEE: 'CANCELLATION_FEE',
  LOADING_FEE: 'LOADING_FEE',
  MULTIPICKUP_FEE: 'MULTIPICKUP_FEE',
  MULTIDROPOFF_FEE: 'MULTIDROPOFF_FEE',
  OTHER_FEE: 'OTHER_FEE',
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
  OVERNIGHT_LOADING_FEE: 'OVERNIGHT_LOADING_FEE',
  OVERNIGHT_UNLOADING_FEE: 'OVERNIGHT_UNLOADING_FEE',
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
};

export const BTMS_FEE_TYPES = {
  SHIPMENT: 'SHIPMENT_FEE',
  LOADING: 'LOADING_FEE',
  UNLOADING: 'UNLOADING_FEE',
  OTHER: 'OTHER_LOCATION_FEE',
  OVERNIGHT_LOADING: 'OVERNIGHT_LOADING_FEE',
  OVERNIGHT_UNLOADING: 'OVERNIGHT_UNLOADING_FEE',
  RETURNS: 'ADDITIONAL_FEE',
  ADDITIONAL: 'ADDITIONAL_FEE',
  TICKET: 'ADDITIONAL_FEE',
  CLAIMS: 'CLAIMS',
  INSURANCE: 'ADJUSTMENT_FEE',
  PENALTY: 'ADJUSTMENT_FEE',
  ADJUSTMENT: 'ADJUSTMENT_FEE',
  OTHER_ADJUSTMENT: 'ADJUSTMENT_FEE',
};

export const FILE_UPLOAD_SOURCE = {
  CA: 'CA',
  DA: 'DA',
  TA: 'TA',
  TMS: 'TMS',
  NFT: 'NFT',
  BTMS: 'BTMS',
};

export const FILE_PREVIEW_TYPE = {
  IMAGE: 'IMAGE',
  GDRIVE_FOLDER: 'GDRIVE_FOLDER',
  GDRIVE_FILE: 'GDRIVE_FILE',
  PDF: 'PDF',
};

export const MIME_TO_FILE_PREVIEW_TYPE_MAP = {
  'image/jpeg': FILE_PREVIEW_TYPE.IMAGE,
  'image/png': FILE_PREVIEW_TYPE.IMAGE,
  'application/pdf': FILE_PREVIEW_TYPE.PDF,
};

export const INVOICE_STATUS = {
  NOT_INVOICED_YET: null,
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  INVOICE_GENERATED: 'INVOICE_GENERATED',
  CREATED: 'CREATED',
};

export const CUSTOMIZABLE_INVOICE_STATUSES = {
  NOT_INVOICED: 'NOT_INVOICED',
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED',
  WAITING_FOR_CLAIM_APPROVAL: 'WAITING_FOR_CLAIM_APPROVAL',
  WAITING_FOR_POD: 'WAITING_FOR_POD',
  ONGOING_SHIPMENT: 'ONGOING_SHIPMENT',
  SHIPPER_INVOICE_IS_BEING_PROCESSED: 'SHIPPER_INVOICE_IS_BEING_PROCESSED',
  WAITING_FOR_INVOICE_PAID: 'WAITING_FOR_INVOICE_PAID',
  EXPIRED_STNK: 'EXPIRED_STNK',
};

export const PAYMENT_PROJECT_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  DRAFT: 'DRAFT',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
  NEED_REVISION: 'NEED_REVISION',
  VERIFIED: 'VERIFIED',
};

export const PAYMENT_PROJECT_STATUS_COLORS = {
  [PAYMENT_PROJECT_STATUSES.ACTIVE]: 'success',
  [PAYMENT_PROJECT_STATUSES.INACTIVE]: 'danger',
  [PAYMENT_PROJECT_STATUSES.UNDER_REVIEW]: 'orangeyYellow',
  [PAYMENT_PROJECT_STATUSES.REJECTED]: 'danger',
  [PAYMENT_PROJECT_STATUSES.DRAFT]: 'heavyDarkGrey',
};

export const PAYMENT_PROJECT_BANNER_TYPE = {
  SUCCESS: 'SUCCESS',
  OPTIONAL_DOCUMENT: 'OPTIONAL_DOCUMENT',
  VERIFICATION_PROCESS: 'VERIFICATION_PROCESS',
  NEED_REVISION: 'NEED_REVISION',
  REJECTED: 'REJECTED',
};

export const PAYMENT_PROJECT_BANNER_STATUS = {
  [PAYMENT_PROJECT_BANNER_TYPE.SUCCESS]: {
    isBanner: true,
    bannerType: 'SUCCESS',
    bannerTitle: 'Verifikasi selesai dan dokumen sudah sesuai!',
    bannerContent: 'Lanjutkan proses pengajuan dengan klik tombol ‘Lanjut’ di bagian bawah kanan halaman ini',
    borderColor: '#04610F',
    color: '#E6FDE7',
  },
  [PAYMENT_PROJECT_BANNER_TYPE.OPTIONAL_DOCUMENT]: {
    isBanner: true,
    bannerType: 'OPTIONAL_DOCUMENT',
    bannerTitle: 'Perbesar kesempatan pengajuan Anda diterima!',
    bannerContent: 'Upload dokumen di bawah ini untuk memperbesar kesempatan Anda dalam menerima Pembayaran Cepat',
    borderColor: '#04070C',
    color: '#EBF0F5',
  },
  [PAYMENT_PROJECT_BANNER_TYPE.VERIFICATION_PROCESS]: {
    isBanner: true,
    bannerType: 'VERIFICATION_PROCESS',
    bannerTitle: 'Proses Verifikasi',
    bannerContent: 'Dokumen tidak dapat dihapus, diubah, atau ditambah selama proses verifikasi berlangsung',
    borderColor: '#04070C',
    color: '#EBF0F5',
  },
  [PAYMENT_PROJECT_BANNER_TYPE.NEED_REVISION]: {
    isBanner: true,
    bannerType: 'NEED_REVISION',
    bannerTitle: 'Mohon Upload Ulang Dokumen Anda',
    bannerContent: 'Upload ulang dokumen dengan tanda ‘Tidak Sesuai’. Pastikan dokumen mudah dibaca dan sesuai dengan yang diminta',
    borderColor: '#C09F10',
    color: '#FFFBE7',
  },
  [PAYMENT_PROJECT_BANNER_TYPE.REJECTED]: {
    isBanner: true,
    bannerType: 'REJECTED',
    bannerTitle: 'Maaf, Pengajuan Anda Ditolak',
    bannerContent: 'Silakan hubungi representatif Kargo untuk informasi lebih lanjut',
    borderColor: '#AE1B18',
    color: '#FDE9E8',
  },
};

export const PAYMENT_SUMMARIES_SECTION_STATUSES = {
  DISBURSED: 'disbursedCount',
  NEED_INVOICED: 'notInvoicedCount',
  ON_HOLD: 'onHoldCount',
  PROCESS: 'inProcessCount',
  // Aggregated status form NEED_INVOICED and ON_HOLD and PROCESS
  NOT_DISBURSED: 'notDisbursedCount',
};

export const PAYMENT_SUMMARIES_STATUSES = {
  // Do not sort this, needed for filters grouping / sequencing
  REVIEW_SHIPMENT_FEE: 'REVIEW_SHIPMENT_FEE',
  REVIEW_IN_PROCESS: 'REVIEW_IN_PROCESS',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  PARTIALLY_DISBURSED: 'PARTIALLY_DISBURSED',
  SHIPPER_INVOICE_IS_BEING_PROCESSED: 'SHIPPER_INVOICE_IS_BEING_PROCESSED',
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_TKBM: 'WAITING_FOR_TKBM',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  WAITING_FOR_PENALTY: 'WAITING_FOR_PENALTY',
  WAITING_FOR_INVOICE_REVISION: 'WAITING_FOR_INVOICE_REVISION',
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  DISBURSED: 'DISBURSED',
  FULLY_INVOICED: 'FULLY_INVOICED',
  NOT_INVOICED: 'NOT_INVOICED',
  ONGOING_SHIPMENT: 'ONGOING_SHIPMENT',
  PARTIALLY_INVOICED: 'PARTIALLY_INVOICED',
  ...CUSTOMIZABLE_INVOICE_STATUSES,
};

export const CUSTOMIZABLE_INVOICE_STATUS_COLOR = {
  [PAYMENT_SUMMARIES_SECTION_STATUSES.DISBURSED]: 'success',
  [PAYMENT_SUMMARIES_SECTION_STATUSES.NEED_INVOICED]: 'warning',
  [PAYMENT_SUMMARIES_SECTION_STATUSES.ON_HOLD]: 'darkOrange',
  [PAYMENT_SUMMARIES_SECTION_STATUSES.PROCESS]: 'primary',
  [PAYMENT_SUMMARIES_SECTION_STATUSES.NOT_DISBURSED]: 'warning',
};

export const PAYMENT_SUMMARIES_STATUS_COLOR = {
  [PAYMENT_SUMMARIES_STATUSES.CANCELLED]: 'warning',
  [PAYMENT_SUMMARIES_STATUSES.DISBURSED]: 'success',
  [PAYMENT_SUMMARIES_STATUSES.NOT_INVOICED]: 'danger',
  [PAYMENT_SUMMARIES_STATUSES.ONGOING_SHIPMENT]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.ON_HOLD]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_DISBURSED]: 'primary',
  [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_INVOICED]: 'warning',
  [PAYMENT_SUMMARIES_STATUSES.REJECTED]: 'warning',
  [PAYMENT_SUMMARIES_STATUSES.REQUEST_APPROVED]: 'primary',
  [PAYMENT_SUMMARIES_STATUSES.REVIEW_SHIPMENT_FEE]: 'primary',
  [PAYMENT_SUMMARIES_STATUSES.REVIEW_IN_PROCESS]: 'primary',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_CLAIM]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_OVERNIGHT]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_PENALTY]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_TKBM]: 'darkOrange',
  [PAYMENT_SUMMARIES_STATUSES.EXPIRED_STNK]: 'darkGrey',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_POD]: 'darkGrey',
  [PAYMENT_SUMMARIES_STATUSES.SHIPPER_INVOICE_IS_BEING_PROCESSED]: 'darkGrey',
  [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_INVOICE_PAID]: 'darkGrey',
};

export const INVOICE_PAYMENT_STATUS = {
  CREATED: 'CREATED',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REVISION_CREATED: 'REVISION_CREATED',
  REVISION_SUBMITTED: 'REVISION_SUBMITTED',
  PHYSICALLY_SENT: 'PHYSICALLY_SENT',
  PHYSICALLY_RECEIVED: 'PHYSICALLY_RECEIVED',
  PHYSICALLY_APPROVED: 'PHYSICALLY_APPROVED',
  ON_HOLD: 'ON_HOLD',
  CANCELLED: 'CANCELLED',
  PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
  PAID: 'PAID',
};

export const INVOICE_STATUS_COLORS = {
  [INVOICE_PAYMENT_STATUS.CREATED]: 'primary',
  [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'warning',
  [INVOICE_PAYMENT_STATUS.APPROVED]: 'success',
  [INVOICE_PAYMENT_STATUS.REJECTED]: 'danger',
  [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'danger',
  [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'warning',
  [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'warning',
  [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'warning',
  [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'success',
  [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'warning',
  [INVOICE_PAYMENT_STATUS.CANCELLED]: 'danger',
  [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'warning',
  [INVOICE_PAYMENT_STATUS.PAID]: 'success',
  [CUSTOMIZABLE_INVOICE_STATUSES.NOT_INVOICED]: 'primary',
  [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_CLAIM_APPROVAL]: 'warning',
  [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_POD]: 'darkGrey',
  [CUSTOMIZABLE_INVOICE_STATUSES.EXPIRED_STNK]: 'danger',
  [CUSTOMIZABLE_INVOICE_STATUSES.SHIPPER_INVOICE_IS_BEING_PROCESSED]: 'darkGrey',
  [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_INVOICE_PAID]: 'darkGrey',
  [CUSTOMIZABLE_INVOICE_STATUSES.ONGOING_SHIPMENT]: 'darkGrey',
};

export const BROKERAGE_INVOICE_STATUS = {
  NOT_INVOICED: 'NOT_INVOICED',
  INVOICED: 'INVOICED',
};

export const INVOICE_STATUS_SEQUENCE = [
  INVOICE_STATUS.NOT_INVOICED_YET,
  INVOICE_STATUS.PAYMENT_PENDING,
  INVOICE_STATUS.PAID,
];

export const INVOICE_STATUS_COLOR = {
  [INVOICE_STATUS.NOT_INVOICED_YET]: 'darkGrey',
  [INVOICE_STATUS.PAYMENT_PENDING]: 'orangeyYellow',
  [INVOICE_STATUS.PAID]: 'success',
  [INVOICE_PAYMENT_STATUS.APPROVED]: 'success',
  [INVOICE_PAYMENT_STATUS.REJECTED]: 'darkGrey',
};

export const DISPLAY_MODE = {
  READ: 'READ',
  WRITE: 'WRITE',
  PRINT: 'PRINT',
};

export const DOF_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const DOF_SHIPMENT_STATUSES = {
  POL_PENDING: 'POL_PENDING',
  POL_ACCEPTED: 'POL_ACCEPTED',
  POD_ACCEPTED: 'POD_ACCEPTED',
  POD_REJECTED: 'POD_REJECTED',
  POL_REJECTED: 'POL_REJECTED',
};

export const DOF_SHIPMENT_STATUS_COLORS = {
  [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'darkGrey',
  [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'primary',
  [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'warning',
  [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'warning',
};

export const SWIFT_PAYMENT_REQUEST_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  DISBURSED: 'DISBURSED',
  DOF2_PROCESSED_PARTIALLY: 'DOF2_PROCESSED_PARTIALLY',
  SECOND_DISBURSEMENT_IN_PROCESS: 'SECOND_DISBURSEMENT_IN_PROCESS',
  SECOND_DISBURSEMENT_DISBURSED: 'SECOND_DISBURSEMENT_DISBURSED',
  THIRD_DISBURSEMENT_IN_PROCESS: 'THIRD_DISBURSEMENT_IN_PROCESS',
  THIRD_DISBURSEMENT_DISBURSED: 'THIRD_DISBURSEMENT_DISBURSED',
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
};

export const SWIFT_PAYMENT_REQUEST_STATUS_COLORS = {
  [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'danger',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'warning',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'success',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'primary',
  [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'success',
};

export const SWIFT_PAYMENT_DOCUMENT_STATUSES = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const DOF2_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE = {
  CLAIM: 'CLAIM',
  TRANSFER_FEE: 'TRANSFER_FEE',
  ADDITION_IN_DO_PRICE: 'SHIPMENT_FEE_ADDITION',
  REDUCE_IN_DO_PRICE: 'SHIPMENT_FEE_DEDUCTION',
  OTHERS: 'OTHERS',
};

export const DOF2_REQUEST_ON_HOLD_REASON_OPTIONS = [
  DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED,
  DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE,
  DOF2_REQUEST_ON_HOLD_REASON.OTHER,
];

export const DOF_REQUEST_REJECTION_REASON = {
  POL_BLUR: 'POL_BLUR',
  POD_BLUR: 'POD_BLUR',
  POL_SHIPMENT_NUMBER_MISMATCH: 'POL_SHIPMENT_NUMBER_MISMATCH',
  POD_SHIPMENT_NUMBER_MISMATCH: 'POD_SHIPMENT_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const DOF_REQUEST_REJECTION_REASON_GROUP = {
  POL: [
    DOF_REQUEST_REJECTION_REASON.POL_BLUR,
    DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER,
  ],
  POD: [
    DOF_REQUEST_REJECTION_REASON.POD_BLUR,
    DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH,
    DOF_REQUEST_REJECTION_REASON.OTHER,
  ],
};

export const PODF_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const PODF_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const PODF_REQUEST_ON_HOLD_REASON = {
  ALL_DOCUMENTS_REJECTED: 'ALL_DOCUMENTS_REJECTED',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  LATE_PAYMENT_ISSUE: 'LATE_PAYMENT_ISSUE',
  OTHER: 'OTHER',
};

export const DOF2_INVOICE_REJECTION_REASON = {
  INVOICE_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const DOF2_INVOICE_RECEIPT_REJECTION_REASON = {
  INVOICE_RECEIPT_BLUR: 'INVOICE_BLUR',
  INVOICE_NUMBER_MISMATCH: 'INVOICE_NUMBER_MISMATCH',
  OTHER: 'OTHER',
};

export const SWIFT_PAYMENT_TYPES = {
  DOF: 'DOF',
  PODF: 'PODF',
  DOF2: 'DOF-2',
};

export const PODF_FIRST_DISBURSEMENT_PERCENTAGE = 80;

export const DOF_THIRD_DISBURSEMENT_PERCENTAGE = 20;

export const DOF2_ADDITIONAL_FEE = 80;

export const DOF2_ELIGIBILITY_STATUS = {
  ELIGIBLE: 0,
  SHIPMENT_NOT_COMPLETED: 1,
  DO_IS_ALREADY_IN_ANOTHER_DOF2_REQUEST: 2,
  DO_IS_ALREADY_IN_ANOTHER_INVOICE: 3,
  DOF_NOT_DISBURSED_YET: 4,
  UNDEFINED_BACKEND_CODE: 5,
  DOF_REQUEST_NOT_FOUND: 6,
};

export const DOF_FIRST_DISBURSEMENT_PERCENTAGE = 50;

export const DOF_SECOND_DISBURSEMENT_PERCENTAGE = 30;

export const DOF_ADDITIONAL_FEES_SECOND_DISBURSEMENT_PERCENTAGE = 80;

export const DOF_THIRD_INVOICE_PPH_CLAIM_TRANSFER_FEE_ADJUSTMENT_FEE_PERCENTAGE = 20;

export const FIRST_MILE_USER_ACCESS_TYPE = {
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  CONSIGNEE: 'CONSIGNEE',
};

export const CONTAINER_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BATCH_UPDATE: 'BATCH_UPDATE',
};

export const CONTAINER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  BATCH_UPDATE_FORM: 'BATCH_UPDATE_FORM',
};

export const CONTAINER_STATUSES = {
  ARRIVED_AT_PORT: 'ARRIVED_AT_PORT',
  PIB: 'PIB',
  NO_NOA: 'NO_NOA',
  NOA: 'NOA',
  SPPB: 'SPPB',
  WAITING_FOR_SPPB: 'WAITING_FOR_SPPB',
  WAITING_FOR_ORIGINAL_DOC: 'WAITING_FOR_ORIGINAL_DOC',
  QUARANTINED: 'QUARANTINED',
  ON_SHIPMENT: 'ON_SHIPMENT',
  MOVED_TO_HOLDING: 'MOVED_TO_HOLDING',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  INVOICED: 'INVOICED',
  PAID: 'PAID',
};

export const CONTAINER_STATUS_COLOR = {
  [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'primary',
  [CONTAINER_STATUSES.ON_SHIPMENT]: 'primary',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [CONTAINER_STATUSES.PAYMENT_PENDING]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'warning',
  [CONTAINER_STATUSES.PAID]: 'success',
  [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'primary',
  [CONTAINER_STATUSES.PIB]: 'primary',
  [CONTAINER_STATUSES.NO_NOA]: 'warning',
  [CONTAINER_STATUSES.NOA]: 'primary',
  [CONTAINER_STATUSES.SPPB]: 'primary',
  [CONTAINER_STATUSES.QUARANTINED]: 'primary',
  [CONTAINER_STATUSES.INVOICED]: 'success',
};

export const FEE_REQUEST_STATUS = {
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  DISBURSED: 'DISBURSED',
  FEE_REJECTED: 'FEE_REJECTED',
  ON_HOLD: 'ON_HOLD',
  PAID: 'PAID',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REQUEST_APPROVED: 'REQUEST_APPROVED',
  REQUEST_REJECTED: 'REQUEST_REJECTED',
  REQUEST_SENT: 'REQUEST_SENT',
  REVIEWED: 'REVIEWED',
  SHIPPER_PENDING: 'SHIPPER_PENDING',
};

export const FEE_REQUEST_STATUS_COLOR = {
  [FEE_REQUEST_STATUS.APPROVED]: 'success',
  [FEE_REQUEST_STATUS.CANCELLED]: 'dark',
  [FEE_REQUEST_STATUS.DISBURSED]: 'success',
  [FEE_REQUEST_STATUS.FEE_REJECTED]: 'danger',
  [FEE_REQUEST_STATUS.ON_HOLD]: 'warning',
  [FEE_REQUEST_STATUS.PAID]: 'success',
  [FEE_REQUEST_STATUS.PENDING]: 'warning',
  [FEE_REQUEST_STATUS.REJECTED]: 'danger',
  [FEE_REQUEST_STATUS.REQUEST_APPROVED]: 'success',
  [FEE_REQUEST_STATUS.REQUEST_REJECTED]: 'danger',
  [FEE_REQUEST_STATUS.REQUEST_SENT]: 'dark',
  [FEE_REQUEST_STATUS.REVIEWED]: 'dark',
  [FEE_REQUEST_STATUS.SHIPPER_PENDING]: 'warning',
};

export const FIRST_MILE_DOCUMENT_TYPES = {
  HOUSE_BILL_OF_LADING: 'HOUSE_BILL_OF_LADING',
  BILL_OF_LADING: 'BILL_OF_LADING',
  NOTICE_OF_ARRIVAL: 'NOTICE_OF_ARRIVAL',
  PIB: 'PIB',
  QUARANTINE: 'QUARANTINE',
  EBILLING: 'EBILLING',
  SHIPPING_LINE_DELIVERY_ORDER: 'SHIPPING_LINE_DELIVERY_ORDER',
  STORAGE_CHARGES: 'STORAGE_CHARGES',
  TILA: 'TILA',
  SPPB: 'SPPB',
  CONTAINER_RENTAL: 'CONTAINER_RENTAL',
  PACKING_LIST: 'PACKING_LIST',
  MATERIAL_SAFETY_AND_DATA_SHEET: 'MATERIAL_SAFETY_AND_DATA_SHEET',
  CERTIFICATE_OF_ORIGIN: 'CERTIFICATE_OF_ORIGIN',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOLO: 'LOLO',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL_OF_LADING_INVOICE: 'BILL_OF_LADING_INVOICE',
};

export const FIRST_MILE_CONTAINER_SIZES = {
  '20_FEET': '20_FEET',
  '20_FR': '20_FR',
  '20_GP': '20_GP',
  '20_OT': '20_OT',
  '20_OW': '20_OW',
  '40_FEET': '40_FEET',
  '40_FR': '40_FR',
  '40_GP': '40_GP',
  '40_HC': '40_HC',
  '40_OW': '40_OW',
  '40_OT': '40_OT',
  '45_FEET': '45_FEET',
};

export const FIRST_MILE_COMPANY_CONSTANT_TYPES = {
  PORT: 'PORT',
  SUPPLIER: 'SUPPLIER',
  SHIPPING_LINE: 'SHIPPING_LINE',
  CONSIGNEE: 'CONSIGNEE',
  NOTIFY_PARTY: 'NOTIFY_PARTY',
  TERMINAL: 'TERMINAL',
};

export const EMPLOYEE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
};

export const APP_CONTEXT_STORAGE_KEY = 'appContext';

export const COACH_MARK_STORAGE_KEY = {
  VIEWED_FROM_ON_CALL: 'viewedOnCallCoachMark',
  VIEWED_FROM_DEDICATED: 'viewedDedicatedCoachMark',
};

export const BANNER_STORAGE_KEY = 'BannerFlags';

export const COUNTRY_CODE_STORAGE_KEY = 'countryCode';

export const TLO_STORAGE_KEY = 'TLO';

export const FREE_TRIAL_LOGIN_FLAG = 'login-expired-flag';
export const EXPIRED_SUBSCRIPTION_LOGIN_FLAG = 'subscription-expired-flag';

export const SHIPMENT_TYPE = {
  MT: 'MT',
  GT: 'GT',
  W2W: 'W2W',
  F2DEPO: 'F2DEPO',
  F2DIST: 'F2DIST',
  F2MT: 'F2MT',
  F2W: 'F2W',
  W2DEPO: 'W2DEPO',
  W2DIST: 'W2DIST',
  W2MT: 'W2MT',
};

export const PHONE_NUMBER_STATUS = {
  FIRST_TIME_SIGN_IN: 'FIRST_TIME_SIGN_IN',
  NOT_REGISTERED: 'NOT_REGISTERED',
  REGISTERED: 'REGISTERED',
};

export const PRIVATE_LINK_STATUS = {
  EXPIRED: 'EXPIRED',
  VALIDATED: 'VALIDATED',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
};

export const PRICING_RATE_TYPES = {
  PER_CBM: 'PER_CBM',
  PER_KG: 'PER_KG',
  PER_LANE: 'PER_LANE',
  PER_ROUTE: 'PER_ROUTE',
  PER_UNIT: 'PER_UNIT',
};

export const MULTIPLE_SHIPMENT_TYPE = {
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP',
};

export const CUSTOM_CITY_REGION_MAPPING_METADATA = 'custom_city_region_mapping';

export const CUSTOM_COMPANY_ROLES_METADATA = 'company_roles';

export const DANONE_USER_ACCESS_TYPE = {
  DANONE_LOGISTIC: 'DANONE_LOGISTIC',
  DANONE_PROCUREMENT: 'DANONE_PROCUREMENT',
};

export const NOTIFICATION_ACTION_CODE = {
  VIEW_DO_DETAILS: '5-32',
};

export const POD_STATUS = {
  ARRIVING_AT_PITSTOP: 'ARRIVING_AT_PITSTOP',
  RECEIVED: 'RECEIVED',
  DELIVERING_TO_AR: 'DELIVERING_TO_AR',
  DELIVERING_TO_SHIPPER: 'DELIVERING_TO_SHIPPER',
  DELIVERED_TO_AR: 'DELIVERED_TO_AR',
  DELIVERED_TO_SHIPPER: 'DELIVERED_TO_SHIPPER',
};

export const VENDOR_ASSIGNMENT_TYPE = {
  BROADCAST_CONTRACTED: 'BROADCAST_CONTRACTED',
  PRIVATE_BIDDING: 'PRIVATE_BIDDING',
  SINGLE_CONTRACTED: 'SINGLE_CONTRACTED',
};

export const CUSTOM_INVOICE_FEE_TYPES = {
  SHIPMENT_FEE: 'SHIPMENT_FEE',
  TRUCKING_FEE: 'TRUCKING_FEE',
  OVERNIGHT_FEE: 'OVERNIGHT_FEE',
  LOADING_FEE: 'LOADING_FEE',
  UNLOADING_FEE: 'UNLOADING_FEE',
  MULTIPICKUP_FEE: 'MULTIPICKUP_FEE',
  MULTIDROPOFF_FEE: 'MULTIDROPOFF_FEE',
  OTHER_FEE: 'OTHER_FEE',
  TICKET_FEE: 'TICKET_FEE',
  // BTMS_FEE_TYPES
  OTHER_LOCATION_FEE: 'OTHER_LOCATION_FEE',
  OVERNIGHT_LOADING_FEE: 'OVERNIGHT_LOADING_FEE',
  OVERNIGHT_UNLOADING_FEE: 'OVERNIGHT_UNLOADING_FEE',
  // Might need to fix these
  RETURNS: 'ADDITIONAL_FEE',
  ADDITIONAL: 'ADDITIONAL_FEE',
  TICKET: 'ADDITIONAL_FEE',
  CLAIMS: 'CLAIMS',
  INSURANCE: 'ADJUSTMENT_FEE',
  PENALTY: 'ADJUSTMENT_FEE',
  ADJUSTMENT: 'ADJUSTMENT_FEE',
  OTHER_ADJUSTMENT: 'ADJUSTMENT_FEE',
  // Will refactor this on the next sprint
  // TODO, Need to confirm with Copywrite/Design for translation
  shipment: 'shipment',
  additional: 'additional',
  unloading: 'unloading',
  loading: 'loading',
  overnight_unloading: 'overnight_unloading',
  overnight_loading: 'overnight_loading',
  other: 'other',
  // First Mile Fees
  demurrage: 'demurrage',
  storage: 'storage',
  thc_lolo: 'thc_lolo',
};

export const CUSTOM_INVOICE_FEE_STATUSES = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAID: 'PAID',
  NOT_INVOICED: '',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CUSTOM_INVOICE_FEE_UNITS = {
  PER_CBM: 'PER_CBM',
  PER_ROUTE: 'PER_ROUTE',
  PER_KG: 'PER_KG',
};

export const SHIPMENT_EXPENSE_TYPES = {
  TRAVEL_EXPENSE: 'TRAVEL_EXPENSE',
  LOADING_EXPENSE: 'LOADING_EXPENSE',
  UNLOADING_EXPENSE: 'UNLOADING_EXPENSE',
  OVERNIGHT_EXPENSE: 'OVERNIGHT_EXPENSE',
  DRIVER_SALARY_EXPENSE: 'DRIVER_SALARY_EXPENSE',
  OTHER_EXPENSE: 'OTHER_EXPENSE',
};

export const CUSTOM_SHIPMENT_PRICING_TYPES = {
  PER_CBM: 'PER_CBM',
  PER_SHIPMENT: 'PER_ROUTE',
  PER_KG: 'PER_KG',
};

export const SHIPMENT_INVOICE_TYPE = {
  RENTAL: 'RENTAL',
  ONCALL: 'ONCALL',
  PBI: 'PBI',
};

export const PAYMENT_DEDUCTION_TYPE = {
  BEFORE_DP: 'BEFORE_DP',
  AFTER_DP: 'AFTER_DP',
};

export const VEHICLE_REPORT_TYPE = {
  TOTAL_KM: 'TOTAL_KM',
  PER_ROUTE_KM: 'PER_ROUTE_KM',
};

export const ROUTE_TYPES = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
};

export const SHIPMENT_POINT_TYPES = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
  PICKUP_DROPOFF: 'PICKUP_DROPOFF',
};

export const SHIPMENT_POINT_TYPES_DOCUMENT_MAP = {
  [SHIPMENT_POINT_TYPES.PICKUP]: DOCUMENT_TYPES.POL,
  [SHIPMENT_POINT_TYPES.DROPOFF]: DOCUMENT_TYPES.POD,
  [DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: DOCUMENT_TYPES.CLAIMS_AND_RETURNS,
  [DOCUMENT_TYPES.OTHER_DOCUMENT]: DOCUMENT_TYPES.OTHER_DOCUMENT,
};

export const MULTI_PICKUP_POINT_STATUS = {
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
};

export const MULTI_DROPOFF_POINT_STATUS = {
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
};

export const MULTI_SHIPMENT_POINT_OTHER_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
};

export const TRANSPORTER_COMPANY_DOCUMENTS = {
  KTP: 'KTP',
  NPWP: 'NPWP',
  SPPKP: 'SPPKP',
};

export const DOCUMENT_TYPE_TO_TITLE_MAP = [
  {
    type: 'POD',
    title: 'SJ Bongkar',
  },
  {
    type: 'POL',
    title: 'SJ Muat',
  },
  {
    type: 'CAR',
    title: 'Klaim dan Pengembalian',
  },
];

export const HIDE_DRIVER_STATUS = ['UPCOMING'];

export const DRIVER_QUEUES_STATUS = {
  ACTIVE: 'ACTIVE',
};

export const PLATE_NUMBER_HISTORIES_COLUMN = {
  LICENSE_PLATE: 'LICENSE_PLATE',
  UPDATED_BY: 'UPDATED_BY',
  TIMESTAMP: 'TIMESTAMP',
  STATUS: 'STATUS',
};

export const KODE_LAMBUNG_ERROR_CODE = '510';

export const SHIPMENT_ACCEPTANCE_STATUS = {
  ACCEPTED: 'accepted',
  CANCELLED_BY_SHIPPER: 'cancelledByShipper',
  CONFIRMATION_EXPIRED: 'confirmationExpired',
  REJECTED_BY_TRANSPORTER: 'rejectedByTransporter',
};

export const SHIPMENT_ACCEPTANCE_FILENAME = 'penerimaan_do_report';
export const SHIPMENT_TIME_ACCURACY_FILENAME = 'ketepatan_waktu_report';
export const PERFORMANCE_REPORT_FILENAME = 'performa_lokasi_report';

export const SHIPMENT_TIME_ACCURACY_STATUS = {
  ONTIME: 'onTime',
  AHEAD: 'ahead',
  LATE: 'late',
};

export const SHIPMENT_ACCEPTANCE_CSV_HEADERS = [
  'DO Diterima',
  'DO Dibatalkan Shipper',
  'DO Dibatalkan (Other Reasons)',
  'DO Tidak Dikonfirmasi',
  'DO Ditolak Vendor',
  'Total DO',
  'Presentase Penerimaan DO',
];

export const SHIPMENT_TIME_ACCURACY_CSV_HEADERS = [
  'Lebih Cepat',
  'Tepat Waktu',
  'Terlambat',
  'Total',
  'Tipe',
];

export const PERFORMANCE_REPORT_CSV_HEADERS = [
  'Jenis Kendaraan',
  'Rata-rata Waktu Muat',
  'Rata-rata Waktu Bongkar',
  'Rata-rata Waktu Muat - Semua Jenis Armada',
  'Rata-rata Waktu Bongkar - Semua Jenis Armada',
];

export const CHART_DISPLAY_TYPE = {
  DATE: 'DATE',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const LOCATION_CHANGE_REASON = {
  CHANGE_OF_PLANS: 'CHANGE_OF_PLANS',
  LOCATION_IS_FULL: 'LOCATION_IS_FULL',
  OTHERS: 'OTHERS',
};

export const VEHICLE_AVAILABILITY_STATUS = {
  AVAILABLE: 'AVAILABLE',
  PARTIALLY_AVAILABLE: 'PARTIALLY_AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
};

export const VEHICLE_AVAILABLITY_STATUS_COLORS = {
  [VEHICLE_AVAILABILITY_STATUS.AVAILABLE]: 'success',
  [VEHICLE_AVAILABILITY_STATUS.PARTIALLY_AVAILABLE]: 'darkOrange',
  [VEHICLE_AVAILABILITY_STATUS.UNAVAILABLE]: 'danger',
  [VEHICLE_AVAILABILITY_STATUS.UNKNOWN]: 'danger',
};

export const MAX_UTILIZATION_TARGET_ROUTE = 50;

export const TRANSPORTER_INCENTIVE_AMOUNT_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  DISBURSEMENT: 'DISBURSEMENT',
};

// Not sorted on purpose
export const TRANSPORTER_INCENTIVE_BUSINESS_LINE = {
  SME_REFERRAL: 'SME_REFERRAL',
  SME_MID_MILE: 'SME_MID_MILE',
  ENTERPRISE_REFERRAL: 'ENTERPRISE_REFERRAL',
  ENTERPRISE_MID_MILE: 'ENTERPRISE_MID_MILE',
};

export const TRANSPORTER_INCENTIVE_STATUS = {
  APPROVED: 'APPROVED',
  DISBURSED: 'DISBURSED',
};

export const AVAILABLE_NFT_MODULES = {
  ANALYTICS: 'analytics',
  BODY_CODE: 'shipment_body-code',
  BTMS_INTEGRATION: 'shipment_btms-integration',
  CONTAINER_INFO: 'shipment_container-info',
  CUSTOMER_WAREHOUSE_CREATION: 'company-setting_customer-warehouse-creation',
  VEHICLE_QR_CODE: 'company-setting_vehicle-qr-code',
  FLEET_AVAILABILITY: 'fleet_availability',
  FLEET_MONITORING: 'fleet_monitoring',
  EARNING_REPORT: 'finance_earnings-report',
  INVOICING: 'finance_invoicing',
  NFS_FULFILLMENT: 'shipment_nfs-fulfillment',
  SHIPMENT_INCOME: 'finance_shipment-income',
  VARIANT: 'shipment_truck-variant',
};

export const FINANCE_PROJECT_DOCUMENT_TYPE_ARRAY = [
  'mainDocuments', 'legalDocuments', 'financialDocuments', 'customerDocuments',
];

export const FINANCE_PROJECT_DOCUMENT_LIST_TYPE = {
  REQUIRED: 'REQUIRED',
  OTHER: 'OTHER',
  MAIN: 'MAIN',
  LEGAL: 'LEGAL',
  CUSTOMER: 'CUSTOMER',
  FINANCIAL: 'FINANCIAL',
};

export const FINANCE_PROJECT_DOCUMENT_TYPE_NUMBER = {
  MAIN: 1,
  FINANCIAL: 2,
  LEGAL: 3,
  CUSTOMER: 4,
};

export const PAYMENT_PROJECT_SCHEME = {
  QUICK_PAY: 'QUICK_PAY',
  ENTERPRISE_REFERRAL: 'ENTERPRISE_REFERRAL',
};

export const QUICK_PAY_FINANCING_DOCUMENT_TYPE = {
  // MAIN DOCUMENTS
  AKTA_PENDIRIAN: 'DEED_OF_INCORPORATION',
  AKTA_PERUBAHAN_TERAKHIR: 'LATEST_DEED_OF_AMENDMENT',
  KTP_NPWP_DIRECTOR: 'KTP_NPWP_DIRECTOR',
  NPWP_COMPANY: 'NPWP_COMPANY',

  // LEGAL DOCUMENTS
  AKTA_PERUBAHAN_PERUSAHAAN: 'DEED_OF_AMENDMENT',
  NIB_OR_TDP: 'NIB',
  SIUP: 'SIUP_OR_TDP',
  SKDU: 'SKDU',

  // FINANCIAL DOCUMENTS
  BANK_STATEMENT: 'BANK_STATEMENT',
  BUKTI_PELUNASAN_HUTANG: 'CONFIRMATION_OF_LOAN_SETTLEMENT',
  DEBT_REPORT: 'DEBT_REPORT',
  FINANCIAL_REPORT: 'FINANCIAL_REPORT',
  INVOICE_EXAMPLE: 'INVOICE_EXAMPLE',

  // OTHER DOCUMENTS
  AGREEMENT_COOPERATION: 'AGREEMENT_COOPERATION',
  AGREEMENT_CREDIT_CHECK: 'AGREEMENT_CREDIT_CHECK',
  BALANCE_SHEET: 'BALANCE_SHEET',
  CASH_FLOW_STATEMENT: 'CASH_FLOW_STATEMENT',
  CREDIT_REPORT: 'CREDIT_REPORT',
  INCOME_STATEMENT: 'INCOME_STATEMENT',
  KTP_NPWP_COMMISSIONER: 'KTP_NPWP_COMMISSIONER',
  NPWP_CUSTOMER: 'NPWP_CUSTOMER',
  SALES_REPORT: 'SALES_REPORT',
  SK_KEMENKUMHAM: 'SK_KEMENKUMHAM',
  SK_KEMENKUMHAM_AKTA_PERUBAHAN: 'SK_KEMENKUMHAM_AKTA_PERUBAHAN',
};

export const ENTERPRISE_REFERRAL_FINANCING_DOCUMENT_TYPE = {
  // REQUIRED DOCUMENTS
  NPWP_COMPANY: 'NPWP_COMPANY',
  NPWP_CUSTOMER: 'NPWP_CUSTOMER',
  AKTA_PENDIRIAN: 'AKTA_PENDIRIAN',
  SK_KEMENKUMHAM: 'SK_KEMENKUMHAM',
  KTP_CUSTOMER: 'KTP_CUSTOMER',

  // OPTIONAL DOCUMENTS
  BANK_STATEMENT: 'BANK_STATEMENT',
  INVOICE_EXAMPLE: 'INVOICE_EXAMPLE',
  FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
  AGREEMENT_CUSTOMER_COOPERATION: 'AGREEMENT_CUSTOMER_COOPERATION',
  NIB_OR_TDP: 'NIB_OR_TDP',
  SIUP: 'SIUP',
  SKDU: 'SKDU',
  KTP_NPWP_DIRECTOR: 'KTP_NPWP_DIRECTOR',
  KTP_NPWP_COMMISSIONER: 'KTP_NPWP_COMMISSIONER',
  AGREEMENT_COOPERATION: 'AGREEMENT_COOPERATION',
  AKTA_PERUBAHAN_PERUSAHAAN: 'AKTA_PERUBAHAN_PERUSAHAAN',
  SK_KEMENKUMHAM_AKTA_PERUBAHAN: 'SK_KEMENKUMHAM_AKTA_PERUBAHAN',
};

export const FINANCING_DOCUMENT_GROUP = {
  ADDITIONAL: 'ADDITIONAL',
  CUSTOMER: 'CUSTOMER',
  FINANCIAL: 'FINANCIAL',
  LEGAL: 'LEGAL',
  MAIN: 'MAIN',
  TRANSPORTER_LEGAL: 'TRANSPORTER_LEGAL',
  TRANSPORTER_FINANCIAL: 'TRANSPORTER_FINANCIAL',
};

export const FINANCING_SUBMITTED_DOC_REFERENCE = {
  ADDITIONAL_DOCUMENTS: 'FINANCING_PROJECT_ADDITIONAL_DOCUMENTS',
  REQUESTED_DOCUMENTS: 'FINANCING_PROJECT_REQUESTED_DOCUMENTS',
};

export const PROJECT_DOCUMENT_STATUSES = {
  ACTIVE: 'ACTIVE',
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
  NOT_UPLOADED: 'NOT_UPLOADED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REUPLOADED: 'REUPLOADED',
  REQUESTED: 'REQUESTED',
  SUBMITTED: 'SUBMITTED',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
  NEED_REVISION: 'NEED_REVISION',
  VERIFIED: 'VERIFIED',
};

export const PROJECT_STATUS_COLORS = {
  ACTIVE: 'success',
  INACTIVE: 'warning',
  REJECTED: 'danger',
  SUBMITTED: 'warning',
};

export const CHIP_PROJECT_STATUS_COLORS = {
  [PAYMENT_PROJECT_STATUSES.ACTIVE]: '#05B012',
  [PAYMENT_PROJECT_STATUSES.DRAFT]: '#5952FF',
  [PAYMENT_PROJECT_STATUSES.INACTIVE]: '#05B012',
  [PAYMENT_PROJECT_STATUSES.REJECTED]: '#D13042',
  [PROJECT_DOCUMENT_STATUSES.REUPLOADED]: '#249D75',
  [PROJECT_DOCUMENT_STATUSES.REQUESTED]: '#249D75',
  [PROJECT_DOCUMENT_STATUSES.SUBMITTED]: '#058EFF',
  [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: '#0268BA',
  [PROJECT_DOCUMENT_STATUSES.NEED_REVISION]: '#FF9D05',
  [PROJECT_DOCUMENT_STATUSES.VERIFIED]: '#04610F',
};

export const CHIP_DOCUMENT_STATUS_COLORS = {
  [PROJECT_DOCUMENT_STATUSES.APPROVED]: {
    color: '#2BBF8E0D',
    textColor: '#249D75',
  },
  [PROJECT_DOCUMENT_STATUSES.REJECTED]: {
    color: '#D130420D',
    textColor: '#992331',
  },
  [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: {
    color: '#FFF5E6',
    textColor: '#FF9D05',
  },
};

export const PROJECT_DOCUMENT_STATUS_COLORS = {
  [PROJECT_DOCUMENT_STATUSES.APPROVED]: {
    color: '#2BBF8E0D',
    textColor: '#249D75',
  },
  [PROJECT_DOCUMENT_STATUSES.DRAFT]: {
    color: '#5952FF0D',
    textColor: '#5952FF',
  },
  [PROJECT_DOCUMENT_STATUSES.NOT_UPLOADED]: {
    color: '#028EFF0D',
    textColor: '#028EFF',
  },
  [PROJECT_DOCUMENT_STATUSES.PENDING]: {
    color: '#66686A0D',
    textColor: '#66686A',
  },
  [PROJECT_DOCUMENT_STATUSES.REJECTED]: {
    color: '#D130420D',
    textColor: '#992331',
  },
  [PROJECT_DOCUMENT_STATUSES.REQUESTED]: {
    color: '#028EFF0D',
    textColor: '#028EFF',
  },
  [PROJECT_DOCUMENT_STATUSES.UNDER_VERIFICATION]: {
    color: '#66686A0D',
    textColor: '#66686A',
  },
  [PROJECT_DOCUMENT_STATUSES.NEED_REVISION]: {
    color: '#D130420D',
    textColor: '#992331',
  },
  [PROJECT_DOCUMENT_STATUSES.VERIFIED]: {
    color: '#2BBF8E0D',
    textColor: '#249D75',
  },
};

export const FILE_UPLOAD_MODE = {
  SINGLE_PICKUP_DROPOFF: 'SINGLE_PICKUP_DROPOFF',
  MULTIPLE_PICKUP_DROPOFF: 'MULTIPLE_PICKUP_DROPOFF',
};

export const TEMPLATE_SHIPMENT_TYPES = {
  NEW_SHIPMENT: 'NEW_SHIPMENT',
  BACKDATED_SHIPMENT: 'BACKDATED_SHIPMENT',
};

export const SHIPMENT_TEMPLATE_SAVE_TYPE = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
};
export const SHIPMENT_INVOICE_SCOPE = {
  NFT: 'NFT',
  FINANCING: 'FINANCING',
  NFT_ENTERPRISE: 'NFT_ENTERPRISE',
};

export const PBI_PARTNERSHIP_STATUSES = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  TRUSTED: 'TRUSTED',
  VERIFIED: 'VERIFIED',
};

export const PBI_INFO_BANNER_REJECTED_STATUS = {
  DOCS_REUPLOAD_NEEDED: 'DOCS_REUPLOAD_NEEDED',
  OTHER_REASON: 'OTHER_REASON',
};

export const COMPANY_VERIFICATION_LEVEL = {
  CREATED: 'L0',
  TRUSTED: 'L3',
  VERIFIED: 'L2',
};

export const PBI_COMPANY_REQUIRED_DOCUMENTS = {
  AKTA: 'AKTA',
  'SIUP/SIUJPT': 'SIUP/SIUJPT',
  NIB: 'NIB',
  NPWP: 'NPWP',
};

export const PBI_COMPANY_OPTIONAL_DOCUMENTS = {
  TDP: 'TDP',
  SKT: 'SKT',
  SPPKP: 'SPPKP',
  'SPPKP/SKB': 'SPPKP/SKB',
  SKB: 'SKB',
};

export const PBI_PERSONAL_REQUIRED_DOCUMENTS = {
  KK: 'KK',
  KTP: 'KTP',
  SURAT_KUASA: 'SURAT_KUASA',
};

export const PBI_PERSONAL_OPTIONAL_DOCUMENTS = {
  BANK_ACCOUNT_BOOK: 'BANK_ACCOUNT_BOOK',
  KK: 'KK',
  NPWP: 'NPWP',
  SURAT_KUASA: 'SURAT_KUASA',
  SPPKP: 'SPPKP',
  SKB: 'SKB',
};

export const TLO_COMPANY_DOCUMENTS = {
  NRIC: 'NRIC',
  SST: 'SST',
  SSM: 'SSM',
};

export const USER_TYPE = {
  COMPANY: 'COMPANY',
  PERSONAL: 'PERSONAL',
};

export const DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS = {
  DOCUMENT_ORIGINAL: 'Dokumen harus asli.',
  DOCUMENT_CLEAR: 'Dokumen harus jelas (tidak buram dan tidak terpotong).',
  DOCUMENT_NOT_FLASHED: 'Hindari pantulan cahaya (tidak difoto menggunakan flash).',
};

export const DOCUMENT_UPLOAD_TERMS = {
  DOCUMENT_NAME_SAME_AS_REGISTERED_NAME: 'Nama harus sama dengan nama yang didaftarkan.',
  DOCUMENT_VALIDITY: 'Pastikan masa berlaku terlihat jelas.',
  DOCUMENT_VALIDITY_CLEAR: 'Pastikan masa berlaku terlihat jelas dan masih berlaku lebih dari 5 bulan.',
};

export const PBI_DRIVER_DOCUMENTS = {
  SIM: 'SIM',
  KTP: 'KTP',
  NRIC: 'NRIC',
  GDL: 'GDL',
  CDL: 'CDL',
};

export const PBI_PHOTO_UPLOAD_TEMPLATE = {
  [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/ktp.png',
  [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]:
      'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/buku-tabungan.png',
  [PBI_PERSONAL_REQUIRED_DOCUMENTS.KK]:
      'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kk.png',
  [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]:
      'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kk.png',
  [PBI_PERSONAL_REQUIRED_DOCUMENTS.SURAT_KUASA]:
      'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/surat-kuasa.png',
  [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]:
      'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/surat-kuasa.png',
  [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/akta.png',
  [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/siup.png',
  [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/npwp.png',
  [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/tdp.png',
  [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/skt.png',
  [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/sppkp.png',
  [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/skb.png',
  [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/sppkp.png',
  [PBI_DRIVER_DOCUMENTS.SIM]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/sim.png',
  [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]:
    'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/nib.png',
};

export const PBI_VEHICLE_STATUS = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

export const PBI_VEHICLE_STATUS_COLOR = {
  [PBI_VEHICLE_STATUS.ACTIVE]: 'success',
  [PBI_VEHICLE_STATUS.DRAFT]: 'primary',
  [PBI_VEHICLE_STATUS.PENDING]: 'warning',
  [PBI_VEHICLE_STATUS.REJECTED]: 'danger',
};

export const VEHICLE_DOCUMENT_TYPE = {
  STNK: 'STNK',
  KIR: 'KIR',
  PUSPAKOM_INSPECTION: 'PUSPAKOM_INSPECTION',
  ROAD_TAX: 'ROAD_TAX',
  VEHICLE_REGISTRATION: 'VEHICLE_REGISTRATION',
};

export const NUMBER_OF_VEHICLE_DOCUMENT_FILES = {
  [VEHICLE_DOCUMENT_TYPE.STNK]: 2,
  [VEHICLE_DOCUMENT_TYPE.KIR]: 3,
  [VEHICLE_DOCUMENT_TYPE.PUSPAKOM_INSPECTION]: 1,
  [VEHICLE_DOCUMENT_TYPE.ROAD_TAX]: 1,
  [VEHICLE_DOCUMENT_TYPE.VEHICLE_REGISTRATION]: 1,
};

export const PBI_DOCUMENT_REJECTION_REASON = {
  BLUR: 'BLUR',
  DOCUMENT_INCOMPLETE: 'DOCUMENT_INCOMPLETE',
  EXPIRED: 'EXPIRED',
  WRONG_DOCUMENT: 'WRONG_DOCUMENT',
  OTHER: 'OTHER',
};

export const BIDDING_TAB_STATUSES = {
  AVAILABLE: 'available',
  ACTIVE: 'active',
  HISTORY: 'history',
  CONFIRMATION: 'confirmation',
};

export const BIDDING_HISTORY_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  CANCELLED_BY_SYSTEM: 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED_AT_CONFIRMATION: 'CANCELLED_AT_CONFIRMATION',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  REJECTED: 'REJECTED',
  UNFULFILLED: 'UNFULFILLED',
};

export const BIDDING_HISTORY_STATUS_FILTERS = {
  ACCEPTED: [BIDDING_HISTORY_STATUSES.ACCEPTED],
  CANCELLED_BY_SYSTEM: [
    BIDDING_HISTORY_STATUSES.CANCELLED_BY_SYSTEM,
    BIDDING_HISTORY_STATUSES.REJECTED,
  ],
  CANCELLED_BY_TRANSPORTER: [
    BIDDING_HISTORY_STATUSES.CANCELLED_BY_TRANSPORTER,
    BIDDING_HISTORY_STATUSES.CANCELLED_AT_CONFIRMATION,
  ],
  CANCELLED_BY_SHIPPER: [BIDDING_HISTORY_STATUSES.CANCELLED_BY_SHIPPER],
  CANCELLED_BY_INTERNAL: [BIDDING_HISTORY_STATUSES.CANCELLED_BY_INTERNAL],
  UNFULFILLED: [BIDDING_HISTORY_STATUSES.UNFULFILLED],
};

export const SIM_TYPES = {
  A: 'A',
  B_I: 'B I',
  B_I_UMUM: 'B I UMUM',
  B_II: 'B II',
  B_II_UMUM: 'B II UMUM',
};

export const PBI_DRIVER_VERIFICATION_STATUSES = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
};

export const COACH_MARK_CONTENT = {
  DESCRIPTION: 'DESCRIPTION',
  TITLE: 'TITLE',
};

export const SORT_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const FIXED_TABLE_COLUMN = {
  DELETE: 'DELETE',
  NUMBERING: 'NUMBERING',
};

export const SHIPMENT_CANCELLATION_STATUSES = {
  DRIVER_ABSENCE: 'DRIVER_ABSENCE',
  DRIVER_ILL: 'DRIVER_ILL',
  DRIVER_NOT_VACCINATED: 'DRIVER_NOT_VACCINATED',
  EXPIRED_KIR: 'EXPIRED_KIR',
  EXPIRED_SIM: 'EXPIRED_SIM',
  EXPIRED_STNK: 'EXPIRED_STNK',
  VEHICLE_ISSUE: 'VEHICLE_ISSUE',
  VEHICLE_TAX_NOT_PAID: 'VEHICLE_TAX_NOT_PAID',
};

export const BTMS_SHIPMENT_CANCELLATION_STATUSES = {
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  WRONG_BID_INPUT: 'WRONG_BID_INPUT',
  CUSTOMER_ORDER_ABORTED: 'CUSTOMER_ORDER_ABORTED',
  VEHICLE_ISSUE: 'VEHICLE_ISSUE',
  MOVED_TO_ANOTHER_PLATFORM: 'MOVED_TO_ANOTHER_PLATFORM',
  OTHER_REASON: 'OTHER_REASON',
};

export const KARGO_BD_NUMBER = '6281119058080';

export const SUMMARY_GROUP_SHIPMENTS = {
  NEED_CONFIRMATION: 'NEED_CONFIRMATION_TAB',
  SCHEDULED: 'SCHEDULED_TAB',
  ONGOING: 'ONGOING_TAB',
  COMPLETED: 'HISTORY_TAB',
};

export const COMPANY_PBI_VEHICLE_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  AVAILABLE: 'AVAILABLE',
};

export const KARGO_NEXUS_FOR_TRANSPORTERS = 'Kargo Nexus for Transporters';
export const THELORRY_PARTNER_B2B = 'TheLorry Partner B2B';

export const PRICING_SCHEME = {
  PRICING_TYPE: {
    ENTERPRISE: 'ENTERPRISE',
    REGULAR: 'REGULAR',
  },
};

export const KARGO_PLATFORM = {
  NFT: 'nft',
};

export const DATETIME_FORMAT = {
  DD_MM_YYYY: 'DD MM YYYY',
  DD_MMM_YYYY: 'DD MMM YYYY',
};

export const PAYMENT_SCHEME_STATUS = {
  ACTIVE: 'ACTIVE',
  GRACE_PERIOD: 'GRACE_PERIOD',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
};

export const SUBSCRIPTION_STATUS = {
  GRACE_PERIOD: 'GRACE_PERIOD',
};

export const NFT_DEACTIVATION_TYPE = {
  NFT_FREE_TRIAL_EXPIRED: 'NFT_FREE_TRIAL_EXPIRED',
  NFT_SUBSCRIPTION_EXPIRED: 'NFT_SUBSCRIPTION_EXPIRED',
};

export const INVOICE_ATTACHMENT_STATUSES = {
  TAX_INVOICE: 'TAX_INVOICE',
  WET_SIGNED_INVOICE: 'WET_SIGNED_INVOICE',
  OTHER_FEE: 'OTHER_FEE',
};

export const INVOICE_UNPAID_STATUS = [
  'CREATED',
  'SUBMITTED',
  'APPROVED',
  'REJECTED',
  'REVISION_SUBMITTED',
  'PHYSICALLY_SENT',
  'PHYSICALLY_RECEIVED',
  'PHYSICALLY_APPROVED',
  'ON_HOLD',
  'CANCELLED',
  'PAYMENT_REQUESTED',
];

export const NFT_SCOPE = {
  NFT_PBI: 'NFT_PBI',
};

export const EXPEDITION_PROVIDER = {
  GO_SEND: 'GO_SEND',
  GRAB_EXPRESS: 'GRAB_EXPRESS',
  NINJA_XPRESS: 'NINJA_XPRESS',
  ANTERAJA: 'ANTERAJA',
  REX_KIRIMAN_EXPRESS: 'REX_KIRIMAN_EXPRESS',
  LION_PARCEL: 'LION_PARCEL',
  PAXEL: 'PAXEL',
  JNE: 'JNE',
  TIKI: 'TIKI',
  POS_INDONESIA: 'POS_INDONESIA',
  WAHANA: 'WAHANA',
  SICEPAT: 'SICEPAT',
  SICEPAT_SIUNTUNG: 'SICEPAT_SIUNTUNG',
  JNT_EXPRESS: 'JNT_EXPRESS',
  OTHER: 'OTHER',
};

export const CANCELLATION_INVOICE_REASON = {
  NEED_REVISION: 'NEED_REVISION',
  INCOMPLETE: 'INCOMPLETE',
  OTHER_REASON: 'OTHER_REASON',
  REVISION: 'REVISION',
};

export const ONHOLD_INVOICE_REASON = {
  WAITING_FOR_DOCUMENT: 'WAITING_FOR_DOCUMENT',
  WAITING_FOR_CLAIM: 'WAITING_FOR_CLAIM',
  WAITING_FOR_OVERNIGHT: 'WAITING_FOR_OVERNIGHT',
  CHECKING_PROCESS: 'CHECKING_PROCESS',
  OTHER: 'OTHER',
};

export const DEFAULT_MAP_POSITION = {
  CENTER: {
    lat: -6.2008406,
    lng: 106.7987143,
  },
  ZOOM: 8,
};

export const MOCK_PR_RESP = [
  {
    transporterPrice: '2222000',
    disbursementRequestKsuid: null,
    otherPaymentRequestTaxDetail: [],
    paymentRequestTaxDetail: null,
    externalId: '2Hhjw2EDtLFLcjEzdz8VzMUz5v2',
    disbursementScheme: {
      ksuid: '1utAQ55w7mrGisplDvMpMimHYaE',
      name: 'DEFAULT SCHEME TRANSPORTER 50:50',
      __typename: 'DisbursementSchemeType',
    },
    shipper: {
      aliasName: 'Non-Exclusive Shipper',
      ksuid: '1ytWwOKySyay6TrjJwWyalbBtpO',
      name: 'Non-Exclusive Shipper',
      __typename: 'CompanyType',
    },
    deliveryOrder: {
      ksuid: null,
      deliveryOrderKsuid: '2Hhjw2EDtLFLcjEzdz8VzMUz5v2',
      doNumber: 'DO-2989759',
      shipmentDate: '2023-10-12',
      plateColor: 'black_plate',
      plateNumber: 'UK 7799 KK',
      destinationCityName: 'SURAKARTA',
      originCityName: 'KABUPATEN BOYOLALI',
      vehicleTypeId: 'tronton_wingbox',
      vehicleKsuid: '1vj9YyjxDUK2yP2yiWabjq6nRgD',
      status: 'COMPLETED',
      shipmentKsuid: '2Hhjw4tlYlBvWbTPdV6d5NHKmop',
      vesselName: null,
      voyageName: null,
      isDtdJob: false,
      dtdShipperCompanyName: null,
      __typename: 'DeliveryOrderType',
    },
    feeRequests: [
      {
        ksuid: '2HhjwLpujJLxR2Hq4chlrBatPtH',
        feeRequestType: 'shipment',
        isDocumentMissing: false,
        isAmountExceedLimit: false,
        reimbursableAmount: null,
        actualOvernightDuration: null,
        shipperAdjustedOvernightDuration: null,
        shipperOvernightDuration: null,
        shipperOvernightFee: null,
        reviewedBy: 'Brokerage',
        requestedAmount: '1111000',
        approvedAmount: '1111000',
        amountType: 'increment',
        remarks: '1st Payment - KABUPATEN BOYOLALI - SURAKARTA',
        status: 'disbursed',
        updatedAt: '2022-11-21 04:32:03Z',
        disbursementRequestDocuments: [],
        metadata: {
          adjustment: null,
          initialRequestedAmount: null,
          percentage: '50',
          pickupCount: null,
          dropoffCount: null,
          feeDescription: null,
          shipmentPointKsuid: null,
          shipmentPointSequence: null,
          shipmentPointType: null,
          sequence: 1,
          shipmentStage: 'pol_stage',
          __typename: 'FeeRequestMetadataType',
        },
        internalNotes: null,
        nftNotes: null,
        __typename: 'FeeRequestType',
      },
      {
        ksuid: '2Hhjwdt7mDU9Nn1JiCUzoUuUh49',
        feeRequestType: 'shipment',
        isDocumentMissing: false,
        isAmountExceedLimit: false,
        reimbursableAmount: null,
        actualOvernightDuration: null,
        shipperAdjustedOvernightDuration: null,
        shipperOvernightDuration: null,
        shipperOvernightFee: null,
        reviewedBy: 'Brokerage',
        requestedAmount: '1111000',
        approvedAmount: '1111000',
        amountType: 'increment',
        remarks: '2nd Payment - KABUPATEN BOYOLALI - SURAKARTA',
        status: 'disbursed',
        updatedAt: '2022-11-21 04:40:54Z',
        disbursementRequestDocuments: [],
        metadata: {
          adjustment: null,
          initialRequestedAmount: null,
          percentage: '50',
          pickupCount: null,
          dropoffCount: null,
          feeDescription: null,
          shipmentPointKsuid: null,
          shipmentPointSequence: null,
          shipmentPointType: null,
          sequence: 2,
          shipmentStage: 'pod_physical_stage',
          __typename: 'FeeRequestMetadataType',
        },
        internalNotes: null,
        nftNotes: null,
        __typename: 'FeeRequestType',
      },
      {
        ksuid: '2HqFmtlRWQasrypBafP0xuWTNnT',
        feeRequestType: 'penalty',
        isDocumentMissing: false,
        isAmountExceedLimit: false,
        reimbursableAmount: null,
        actualOvernightDuration: null,
        shipperAdjustedOvernightDuration: null,
        shipperOvernightDuration: null,
        shipperOvernightFee: null,
        reviewedBy: 'Brokerage',
        requestedAmount: '20000',
        approvedAmount: '20000',
        amountType: 'deduction',
        remarks: 'Penalty DO-2989759 - UK 7799 KK - 12/10 - BANYUDONO - BANJARSARI',
        status: 'disbursed',
        updatedAt: '2022-11-21 04:32:03Z',
        disbursementRequestDocuments: [],
        metadata: null,
        internalNotes: null,
        nftNotes: null,
        __typename: 'FeeRequestType',
      },
      {
        ksuid: '2HqHA9RTEsOuY6iQAKoZtfiEuov',
        feeRequestType: 'adjustment',
        isDocumentMissing: false,
        isAmountExceedLimit: false,
        reimbursableAmount: null,
        actualOvernightDuration: null,
        shipperAdjustedOvernightDuration: null,
        shipperOvernightDuration: null,
        shipperOvernightFee: null,
        reviewedBy: 'Brokerage',
        requestedAmount: '200000',
        approvedAmount: '200000',
        amountType: 'increment',
        remarks: 'Price Adjustment DO-2989759 - UK 7799 KK - 12/10 - BANYUDONO - BANJARSARI',
        status: 'disbursed',
        updatedAt: '2022-11-21 04:40:54Z',
        disbursementRequestDocuments: [],
        metadata: null,
        internalNotes: null,
        nftNotes: null,
        __typename: 'FeeRequestType',
      },
    ],
    __typename: 'PaymentRequestLineItemType',
    paymentRequestId: 'PR-2022112100002',
    doNumber: 'DO-2989759',
    doStatus: 'COMPLETED',
    doKsuid: '2Hhjw2EDtLFLcjEzdz8VzMUz5v2',
    shipmentDate: '2023-10-12',
    shipmentKsuid: '2Hhjw4tlYlBvWbTPdV6d5NHKmop',
    shipperName: 'Non-Exclusive Shipper',
    transporter: {
      ksuid: '1vLVxyp4c3BmHrKNww3qUxkz3oS',
      name: 'Mira SME Transporter',
      email: 'mira.sme@yopmail.com',
      isPkp: false,
      __typename: 'CompanyType',
    },
    taxShipmentFee: 1111000,
    taxPriceAdjustment: 200000,
    taxOvernightLoading: 0,
    taxOvernightUnloading: 0,
    disbursementSchemeName: 'DEFAULT SCHEME TRANSPORTER 50:50',
    disbursementSchemeKsuid: '1utAQ55w7mrGisplDvMpMimHYaE',
    amount: '1311000',
    adjustmentAmount: null,
    ksuid: '2HqHFP5mr4d1pxePPIBpIek8l9j',
    paymentRequestDocuments: null,
    taxInvoiceNumber: null,
    invoiceNumber: null,
    invoiceDate: null,
    feeRequestsKsuids: [
      {
        ksuid: '2Hhjwdt7mDU9Nn1JiCUzoUuUh49',
        __typename: 'FeeRequestType',
      },
      {
        ksuid: '2HqHA9RTEsOuY6iQAKoZtfiEuov',
        __typename: 'FeeRequestType',
      },
    ],
    status: 'disbursed',
    paymentTaxDetail: [],
    otherPaymentTaxDetail: [],
  },
];

export const PPN_TYPE = {
  EXCLUDE: 'EXCLUDE',
  INCLUDE: 'INCLUDE',
};

export const SHIPMENT_POINT_TYPE = {
  PICKUP: 'PICKUP',
  DROPOFF: 'DROPOFF',
};
export const SHIPMENT_POINT_TYPE_COLORS = {
  PICKUP: '#E6F7FF',
  DROPOFF: '#2BBF8E0D',
};

export const SHIPMENT_POINT_TYPE_TEXT_COLORS = {
  PICKUP: '#1890FF',
  DROPOFF: '#249D75',
};

export const READINESS_SHIPMENT_STATUS = {
  READY: 'READY',
  ABOUT_TO_BE_READY: 'ABOUT_TO_BE_READY',
};

export const READINESS_SHIPMENT_STATUS_COLORS = {
  [READINESS_SHIPMENT_STATUS.READY]: 'success',
  [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'orangeyYellow',
};

export const MONTH_INVOICE_FORMAT = {
  // Don't sort this. It's intended like design
  NUMBER: 'NUMBER',
  ROMAN: 'ROMAN',
  LETTER: 'LETTER',
};

export const YEAR_INVOICE_FORMAT = {
  COMPLETE: 'COMPLETE',
  LAST_TWO_NUMBER: 'LAST_TWO_NUMBER',
};

export const INVOICE_FORMAT_DEFAULT = {
  '/': '/',
  '-': '-',
  ':': ':',
  MONTH_CODE: 'MONTH_CODE',
  YEAR_CODE: 'YEAR_CODE',
  CUSTOMER_CODE: 'CUSTOMER_CODE',
  INVOICE_CODE: 'INVOICE_CODE',
  COMPANY_CODE: 'COMPANY_CODE',
};

export const INVOICE_FORMAT_DEFAULT_TYPE = [
  { type: 'DEFAULT', value: '/' },
  { type: 'DEFAULT', value: '-' },
  { type: 'DEFAULT', value: ':' },
  { type: 'DEFAULT', value: 'MONTH_CODE' },
  { type: 'DEFAULT', value: 'YEAR_CODE' },
  { type: 'DEFAULT', value: 'CUSTOMER_CODE' },
  { type: 'DEFAULT', value: 'INVOICE_CODE' },
  { type: 'DEFAULT', value: 'COMPANY_CODE' },
];

export const EXAMPLE_INVOICE_FORMAT_DATA = [
  { type: 'DEFAULT', value: 'COMPANY_CODE' },
  { type: 'DEFAULT', value: '/' },
  { type: 'DEFAULT', value: 'CUSTOMER_CODE' },
  { type: 'DEFAULT', value: '/' },
  { type: 'DEFAULT', value: 'YEAR_CODE' },
  { type: 'DEFAULT', value: '/' },
  { type: 'DEFAULT', value: 'MONTH_CODE' },
  { type: 'DEFAULT', value: '/' },
  { type: 'DEFAULT', value: 'INVOICE_CODE' },
];

export const FORMAT_TYPE_OPTIONS = {
  CUSTOM: 'CUSTOM',
  DEFAULT: 'DEFAULT',
};

/* Date picker default value */
const today = moment()
  .utc()
  .hours(16)
  .minutes(59)
  .seconds(59)
  .milliseconds(999);
const oneMonthAgo = today
  .clone()
  .subtract(6, 'weeks')
  .hours(17)
  .minutes(0)
  .seconds(0)
  .milliseconds(0);
export const LAST_MONTH_DATE = {
  start: oneMonthAgo.toISOString(),
  end: today.toISOString(),
};

export const WHT_SELECTION_TYPE = {
  WHT15: 'WHT15',
  WHT21: 'WHT21',
  WHT23: 'WHT23',
};

export const LAST_ANNOUNCEMENT_TIMESTAMP_KEY = 'lastAnnouncementTimestamp';
export const LAST_ANNOUNCEMENT_ID_KEY = 'lastAnnouncementId';
export const LAST_VISIT_EXPIRATION_PAGE_DATE = 'visit_expiration_page_date';
export const TRANSPORTER_WEBVIEW_WALLET_URL = `${window.TRANSPORTER_WEBVIEW_URL}/saldo-kargo`;

export const COUNTRIES = {
  ID: 'ID',
  MY: 'MY',
};

export const CURRENCY_CODES = {
  IDR: 'IDR',
  MYR: 'MYR',
  SGD: 'SGD',
  USD: 'USD',
};

export const CURRENCY_LABELS = {
  RUPIAH: 'Rupiah',
  RINGGIT: 'Ringgit',
  SINGAPORE_DOLLAR: 'Singapore Dollar',
  US_DOLLAR: 'US Dollar',
};

export const INSTALL_BANNER_STORAGE_KEY = 'INSTALL_BANNER_STATE';

export const FCM_STORAGE_KEY = 'fcm_token';

export const PUSH_NOTIFICATION_CODES = {
  '5-94': '5-94', // new job on MP
  '5-95': '5-95', // reopened job on MP
  '5-97': '5-97', // wallet notif
  '5-62': '5-62', // manually assigned DO
};

export const DRIVER_DOCUMENT_RULENAME = {
  [COUNTRIES.ID]: 'ONBOARDING_DRIVER_COMPANY_D0_ID',
  [COUNTRIES.MY]: 'ONBOARDING_DRIVER_COMPANY_D0_MY',
};

export const DRIVER_DOCUMENTS = {
  KTP: 'KTP',
  SIM: 'SIM',
  NRIC: 'NRIC',
  GDL: 'GDL',
  CDL: 'CDL',
};

export const DOCUMENT_GROUP_NAME = {
  MAIN_DOCUMENT: 'MAIN_DOCUMENT',
  SECONDARY_DOCUMENT: 'SECONDARY_DOCUMENT',
};
